import * as React from 'react';
import { useIntl } from 'react-intl';
import { useState } from 'react';

import APP from '_machina/react/model/App';

// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import Snackbar from 'ui-component/extended/Snackbar';

import ThemeCustomization from 'themes';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

import AppSnackbar from '_machina/react/AppSnackbar';
import Dialogs from '_machina/react/dialogs';
import initMachina from '_machina';

function SetI18N() {
  const intl = useIntl();
  APP._intl = intl;
  return <></>
}

function App() {
  const [started, setStarted] = useState(false);
  const [error, setError] = useState(null);

    React.useEffect(() => {
    (async () => {
      try {
        await initMachina();
        setStarted(true);
      } catch (e) {
        setError(e);
      }
    })();
  }, []);

  return (
    !started ? (<div>{error ? error.toString() : null}</div>) : (
      <ThemeCustomization>
        <Locales>
          <NavigationScroll>
            <AuthProvider>
              <>
                <SetI18N />
                <Routes />
                <AppSnackbar />
                <Dialogs />
              </>
            </AuthProvider>
          </NavigationScroll>
        </Locales>
      </ThemeCustomization>
    )
  );
}

export default App;
