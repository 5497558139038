import * as React from 'react';

import { useTheme } from '@mui/system';

// material-ui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import CommonTextField from '_machina/react/components/common/fields/CommonTextField';
import { FormattedMessage } from 'react-intl';

export function ValuesTable({ column, values, overridesModel, forceUpdate }) {
  const theme = useTheme();

  const rows = [];
  for (let i = 0; i < values.length; i++) {
    const value = values[i];

    rows.push(
      <TableRow key={value}>
        <TableCell>{value}</TableCell>
        <TableCell>
            <CommonTextField 
              sx={{margin: .5, minWidth: 250}} 
              label={null} 
              value={overridesModel.getColumnValueAlias(column, value, false)}
              onChange={(e) => {
                overridesModel.setColumnValueAlias(column, value, e.target.value);
                forceUpdate();
              }}              
            />
        </TableCell>                
      </TableRow>
    );
  }

  return (
    <MainCard
      key={"ValuesTable"}
      headerSX={{ m: 1.5, p: 0 }}
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 10 : theme.palette.grey[200],
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.grey[200],
      }}
      content={false}
    >
      <TableContainer sx={{ maxHeight: 260 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                }
              }}
            >
              <TableCell key={"value"}>
                <FormattedMessage id="value" />
              </TableCell>
              <TableCell key={"alias"}>
                <FormattedMessage id="valueAlias" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}           
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
}
