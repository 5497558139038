import { SankeyNodeData } from './SankeyNode';

export const COLOR_SCHEME = [
  '#0508E3',
  '#4587E9',
  '#61CEF3',
  '#563E99',
  '#9056FC',
  '#E126FF',
  '#BE2B56',
  '#FF4E4E',
  '#FF984E',
  '#FFA572',
  '#1EEBAA',
  '#73BE03'
];

const MAX_NODE_COUNT = 8;

function distributeNodes(data: SankeyNodeData[]) {
  const sortedData = data
    .filter(item => item.value > 0.1)
    .sort((a, b) => a.value - b.value)
    .slice(0, MAX_NODE_COUNT);

  const array1 = [];
  const array2 = [];

  sortedData.forEach((item, index) => {
    if (index % 2 === 0) {
      array1.push(item);
    } else {
      array2.push(item);
    }
  });

  function arrangeOutwards(arr) {
    const result = new Array(arr.length);
    const midIndex = Math.floor(arr.length / 2);
    let left = midIndex - 1;
    let right = midIndex + 1;

    result[midIndex] = arr.pop();

    while (arr.length > 0) {
      if (left >= 0) {
        result[left] = arr.pop();
        left--;
      }
      if (right < result.length) {
        result[right] = arr.pop();
        right++;
      }
    }

    return result.filter(item => item !== undefined);
  }

  const arrangedArray1 = arrangeOutwards(array1);
  const arrangedArray2 = arrangeOutwards(array2);

  return [arrangedArray1, arrangedArray2];
}

export function buildSankeyNodes(nodes: SankeyNodeData[], selection?: string) {
  const n = [];
  const l = [];

  if (selection && nodes.length > 1) {
    const selectedNode = nodes.find((node) => node.id === selection);
    const filterdNodes = nodes.filter((node) => node.id !== selection);
    const [sources, targets] = distributeNodes(filterdNodes);

    if (selectedNode) {
      n.push({
        id: selectedNode.id,
        title: selectedNode.label,
        metadata: selectedNode
      });
    }

    for (const node of sources) {
      n.push({
        id: node.id,
        title: node.label,
        metadata: node
      });

      l.push({
        source: node.id,
        target: selection,
        value: node.value
      });
    }

    for (const node of targets) {
      n.push({
        id: node.id,
        title: node.label,
        metadata: node
      });

      l.push({
        source: selection,
        target: node.id,
        value: node.value
      });
    }
  }

  return [n, l];
}