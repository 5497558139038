import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CommonTextField from './CommonTextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const formatDateIn = (input) => {
    const inputDate = new Date(input);

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");

    const newDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
    return newDateString
}

export const formatDateOut = (input) => {
    const inputDate = new Date(input);

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");
    const seconds = String(inputDate.getSeconds()).padStart(2, "0");

    const newDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return newDateString
}

export default function CommonDateTimeField({ ...other }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                slotProps={{textField: {variant: 'outlined'}}}
                {...other}
            />
        </LocalizationProvider>
    )
}