import * as React from 'react';
import { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import PREFS from '_machina/Prefs';

import {
    MenuItem,
    TableCell,
} from '@mui/material';

import { useNavigate } from "react-router-dom";

import { openConfirmDialog } from '_machina/react/components/common/dialog/ConfirmDialog';
import { getDefaultModelUrl } from '_machina/assets/images';
import { LOGGER } from '_machina/util/Logging';
import APP from '_machina/react/model/App';
import MODEL from '_machina/react/model/Model';
import MODEL_LIST from '_machina/react/model/ModelList';
import CommonCardView from '_machina/react/components/common/CommonCardView';
import CommonLabeledImage from '_machina/react/components/common/CommonLabeledImage';
import CommonMenu from '_machina/react/components/common/CommonMenu';
import CommonPageHeader from '_machina/react/components/common/CommonPageHeader';
import CommonTable from '_machina/react/components/common/table/CommonTable';
import CommonTableEditColumn from '_machina/react/components/common/table/CommonTableEditColumn';
import CommonTableViewColumn from '_machina/react/components/common/table/CommonTableViewColumn';
import DeleteButton from '_machina/react/components/common/toolbar/DeleteButton';
import TableSkeleton from '_machina/react/components/common/skeleton/TableSkeleton';
import LabeledImageSkeleton from '_machina/react/components/common/skeleton/LabeledImageSkeleton';
import IconButtonSkeleton from '_machina/react/components/common/skeleton/IconButtonSkeleton';
import TableIconButtonSkeleton from '_machina/react/components/common/skeleton/TableIconButtonSkeleton';
import ImageCardSkeleton from '_machina/react/components/common/skeleton/ImageCardSkeleton';
import PrivateObjectIcon from '_machina/react/components/common/icons/PrivateObjectIcon';

const TABLE_NAME = "models-table";

const modelsTableHeadCells = [
    {
        id: 'name',
        numeric: false,
        label: <FormattedMessage id="model" />,
        align: 'left'
    },
    {
        id: 'view',
        numeric: false,
        label: <FormattedMessage id="view" />,
        align: 'center',
        disableSort: true
    },
    {
        id: 'edit',
        numeric: false,
        label: <FormattedMessage id="edit" />,
        align: 'center',
        disableSort: true
    },
];

const ModelsList = ({ cardMode }) => {
    const [results, setResults] = React.useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuTargetId, setMenuTargetId] = useState(null);

    const navigate = useNavigate();
    const intl = useIntl();

    const handleMenuClick = (id, event) => {
        setMenuAnchorEl(event.currentTarget);
        setMenuTargetId(id);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
    }

    const deleteCommand = async (selected) => {    
        openConfirmDialog(
            <FormattedMessage id={selected.length > 1 ? "delete.models.title" : "delete.model.title"} />,
            <FormattedMessage id={selected.length > 1 ? "delete.models.message" : "delete.model.message"} />,
            async () => {
                try {
                    await MODEL_LIST.deleteModels(selected)
                } catch (e) {
                    LOGGER.error('Error deleting models', e);
                    APP.showErrorMessage(<FormattedMessage id="error.models.deleting" />);
                }
            }
        );
    }

    const editCommand = async (id) => {
        try {
            await MODEL.showModelEditDialog(id, (ok) => {
                if (ok) MODEL_LIST.getModels();
            });                 
        } catch (e) {
            LOGGER.error('Error loading model', e);
            APP.showErrorMessage(<FormattedMessage id="error.model.retrieve" />);
        }
    }

    MODEL_LIST._setResults = setResults;

    const updateResultsCallback = async (page, rowsPerPage, orderBy, orderAscending) => {
        try {
            setTimeout(async () => {
                MODEL_LIST.setPageAndSort(page, rowsPerPage, orderBy, orderAscending);
                MODEL_LIST.setFilter(null);        
                await MODEL_LIST.getModels();
            }, results === null ? 500 : 0);
        } catch (e) {
            LOGGER.error('Error retrieving models', e);
            APP.showErrorMessage(<FormattedMessage id="error.models.retrieving" />);
        }
    };

    return (
        results === null ?
            <TableSkeleton
                tableName={TABLE_NAME}
                cardMode={cardMode}
                headCells={modelsTableHeadCells}
                updateResultsCallback={updateResultsCallback}
                renderToolbarItems={() => {
                    return (
                        <IconButtonSkeleton />
                    )
                }}
                renderRow={() => {
                    return (
                        <>
                            <TableCell>
                                <LabeledImageSkeleton hasSubTitle={false}/>
                            </TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                <TableIconButtonSkeleton />
                            </TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                <TableIconButtonSkeleton />
                            </TableCell>
                        </>                    
                    )
                }}
                renderCard={() => {
                    return <ImageCardSkeleton />
                }}
                rowCount = {cardMode ? 6 : 5}
            /> :    
            <>
                <CommonTable
                    tableName={TABLE_NAME}
                    cardView={cardMode}
                    headCells={modelsTableHeadCells}
                    updateResultsCallback={updateResultsCallback}
                    results={results}
                    renderToolbarItems={(selected) => {
                        return (
                            <>
                                <DeleteButton
                                    disabled={!selected.length}
                                    onClick={() => { deleteCommand(selected) }}
                                />
                            </>
                        )
                    }}
                    renderRow={(row, index) => {
                        return (
                            <>
                                <TableCell>
                                    <CommonLabeledImage 
                                        title={row.Name} 
                                        description={row.Description} 
                                        imageUrl={row.ImageURL} 
                                        rightComponent={MODEL.isPrivate(row) && <PrivateObjectIcon/>}
                                        defaultImageUrl={getDefaultModelUrl()} 
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <CommonTableViewColumn onClick={() => {
                                        navigate(`/view-model/${row.ID}`)
                                    }} />
                                </TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    <CommonTableEditColumn onClick={() => editCommand(row.ID) } />
                                </TableCell>
                            </>
                        )
                    }}
                    renderCard={(row, index) => {
                        return (
                            <CommonCardView
                                onClick={() => {
                                    navigate(`/view-model/${row.ID}`)
                                }} 
                                title={row.Name}
                                description={row.Description}
                                imageUrl={row.ImageURL}
                                defaultImageUrl={getDefaultModelUrl()}
                                upperRightComponent={MODEL.isPrivate(row) && <PrivateObjectIcon forCard={true}/>}
                                handleMenuClick={(e) => { handleMenuClick(row.ID, e) }}
                            />
                        );
                    }}
                />
            {menuAnchorEl && (
                <CommonMenu
                    anchorEl={menuAnchorEl}
                    onClose={closeMenu}
                >
                    <MenuItem onClick={() => {
                            closeMenu();
                            navigate(`/view-model/${menuTargetId}`)
                        }}
                    >
                        {intl.formatMessage({ id: 'view' })}
                    </MenuItem>
                    <MenuItem 
                        onClick={() => {
                            closeMenu();
                            editCommand(menuTargetId);                             
                        }}
                    >
                        {intl.formatMessage({ id: 'edit' })}
                    </MenuItem>
                    <MenuItem onClick={() => {
                            closeMenu();
                            deleteCommand([menuTargetId])                            
                        }}
                    >
                        {intl.formatMessage({ id: 'delete' })}
                    </MenuItem>
                </CommonMenu>
            )}
        </>
    );
}

const ModelsPage = () => {
    const [cardMode, setCardMode] = React.useState(PREFS.getBoolPreference(TABLE_NAME + ".cardMode", true));
    const intl = useIntl();
    return (
        <CommonPageHeader
            createText={intl.formatMessage({ id: 'createModelDots' })}
            cardMode={cardMode}
            setCardMode={(mode) => {
                setCardMode(mode);
                PREFS.setPreference(TABLE_NAME + ".cardMode", mode)
            }}
            title={intl.formatMessage({ id: 'models' })}
            search={true}
            searchPlaceholder={intl.formatMessage({ id: 'searchModels' })}>
            {cardMode ? (
                <ModelsList
                    key={"modelCardMode"}
                    cardMode={true} />
            ) : (
                <ModelsList
                    key={"modelListMode"}
                    cardMode={false} />
            )}
        </CommonPageHeader>
    );
};

export default ModelsPage;


