import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts';

import MainCard from 'ui-component/cards/MainCard';

import { LOGGER } from '_machina/util/Logging';
import colorAssigner from '_machina/util/ColorAssigner';
import APP from '_machina/react/model/App';
import VIEW_MODEL from '_machina/react/model/ViewModel';

function getChartData(submodels) {
  const colors = [];
  const columns = [];
  for (let i = 0; i < submodels.length; i++) {
    colors.push(colorAssigner(submodels[i].PrimaryMetric))
    columns.push(VIEW_MODEL.getOverrides().getColumnAlias(submodels[i].ColumnName))
  }
  return {
    type: 'bar',
    height: 218,
    options: {
      chart: {
        // id: "sub-model-primary-metric-bar",
        sparkline: {
          enabled: true
        },
        events: {
          dataPointSelection: (e, x, z) => {
            const index = z.dataPointIndex;
            (async () => {
              try {
                await VIEW_MODEL.selectSubModel(submodels[index].ID)
              } catch (e) {
                LOGGER.error('Error loading model', e);
                APP.showErrorMessage(<FormattedMessage id="error.model.loading" />);
              }
            })();
          }
        }
      },
      colors: colors,
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
          horizontal: false,
          distributed: true,
        }
      },
      xaxis: {
        categories: ["Confidence"],
        crosshairs: {
          width: 1
        }
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: (e, x) => `${columns[x.dataPointIndex]}:`
          },
        },
        marker: {
          show: false
        }
      }
    },
  };
}

const SubModelPrimaryMetricBarChart = ({ model, subModelId }) => {
  const [count, setCount] = React.useState(null);
  const [series, setSeries] = React.useState([]);
  const [subModels, setSubModels] = React.useState([]);

  React.useEffect(() => {
    const subModels = VIEW_MODEL.getSubModels();
    const data = [];
    for (let i = 0; i < subModels.length; i++) {
      data.push((Math.round((subModels[i].PrimaryMetric * 100) * 100)) / 100.0);
    }
    setSeries([{ data: data }])
    setSubModels(subModels);
    setCount(count + 1);
  }, [model]);

  // React.useEffect(() => {
  //   let index = -1;
  //   for (let i = 0; i < subModels.length; i++) {
  //     if (subModelId === subModels[i].ID) {
  //       index = i;
  //       break;
  //     }
  //   }

  //   if (index >= 0) {
  //     setTimeout(() => {
  //       ApexCharts.exec("sub-model-primary-metric-bar", 'toggleDataPointSelection', 0, index);
  //     }, 1000);
  //   }
  // },[subModelId, subModels])

  return (
    <MainCard title={<FormattedMessage id="modelConfidence" />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Chart
            key={count}
            series={series}
            {...getChartData(subModels)}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default SubModelPrimaryMetricBarChart;
