import React from 'react';

// material-ui
import { FormattedMessage } from 'react-intl';
import { LOGGER } from '_machina/util/Logging';

import { uuidv4 } from '_machina/util/Uuid';
import { getDefaultModelUrl } from '_machina/assets/images';
import { CommonDialog } from '_machina/react/components/common/dialog/CommonDialog';
import APP from '_machina/react/model/App';
import MODEL from '_machina/react/model/Model';
import CURRENT_USER from '_machina/react/model/CurrentUser';
import CommonObjectDetailsTabPanel from '_machina/react/components/common/dialog/CommonObjectDetailsTabPanel';
import CommonImageTabPanel from '_machina/react/components/common/dialog/CommonImageTabPanel';
import Validator from '_machina/react/components/common/validation/Validator';
import { useForceUpdate } from '_machina/react/Util';

let _setUniqueId = null;
let _setCallback = null;
let _setModel = null;
let _chooserState = null;
let _setOwnableUser = null;

const setChooserState = (state) => {
    _chooserState = state;
}

const getChooserState = () => {
    return _chooserState;
}

const validator = new Validator();

const GENERAL_TAB = 0;
const IMAGE_TAB = 1;

export async function openModelEditDialog(model, callback) {
    _chooserState = null;
    _setUniqueId(uuidv4());
    _setCallback(callback);
    _setModel(model);
    _setOwnableUser(await CURRENT_USER.getCurrentUser())
    validator.reset();
}

export default function ModelEditDialog() {
    const [callback, setCallback] = React.useState(null);
    const [uniqueId, setUniqueId] = React.useState(null);
    const [model, setModel] = React.useState(null);
    const [ownableUser, setOwnableUser] = React.useState(null);

    _setUniqueId = setUniqueId;
    _setModel = setModel;
    _setCallback = setCallback;
    _setOwnableUser = setOwnableUser;

    return (
        <ModelEditDialogInner
            key={uniqueId}
            callback={async (ok) => {
                if (!ok) {
                    if (callback?.cb) callback.cb(false)
                    setModel(null); // close dialog
                } else {
                    let succeeded = false;
                    try {
                        await MODEL.updateModel(model);
                        succeeded = true;
                    } catch (e) {
                        LOGGER.error('Error saving model', e);
                        APP.showErrorMessage(<FormattedMessage id="error.models.saving" />);
                    }
                    if (succeeded) {
                        if (callback?.cb) callback.cb(true)
                        setModel(null); // close dialog
                    }
                }
            }}
            model={model}
            setModel={setModel}
            ownableUser={ownableUser}
        />
    )
}

function ModelEditDialogInner({ callback, model, setModel, ownableUser }) {
    const [tab, setTab] = React.useState(GENERAL_TAB);
    const open = model !== null;

    const forceUpdate = useForceUpdate();

    if (!open) return;

    const addValidatorCallback = (key, cb) => {
        validator.addCallback(key, cb);
    }

    const validateCallback = (ok) => {
        if (ok) {
            validator.executeCallbacks();
            const minTab = validator.getMinInvalidTab();
            if (minTab >= 0) {
                setTab(minTab);
                forceUpdate();
                return false;
            }
        }
        callback(ok);
    }

    return (
        <CommonDialog
            title={<FormattedMessage id="editModel" />}
            open={open}
            callback={validateCallback}
            tab={tab}
            setTab={setTab}
            tabs={[{
                value: GENERAL_TAB,
                label: <FormattedMessage id="general" />
            }, {
                value: IMAGE_TAB,
                label: <FormattedMessage id="image" />
            }]}
            tabPanels={
                open && (
                    <>
                        <CommonObjectDetailsTabPanel
                            tab={tab}
                            value={GENERAL_TAB}
                            object={model}
                            setObject={(object) => {
                                setModel({ ...object })
                            }}
                            addValidatorCallback={addValidatorCallback}
                            validator={validator}
                            ownableUser={ownableUser}
                        />
                        <CommonImageTabPanel
                            tab={tab}
                            value={IMAGE_TAB}
                            imageUrl={model.ImageURL}
                            defaultImageUrl={getDefaultModelUrl()}
                            setImageUrl={(imageUrl) => {
                                setModel({ ...model, ImageURL: imageUrl })
                            }}
                            getChooserState={getChooserState}
                            setChooserState={setChooserState}
                        />
                    </>
                )}
        />
    );
}
