import { LOGGER } from '_machina/util/Logging';
import BaseModel from './BaseModel';
import MODEL_SERVICE from '_machina/service/ModelService';
import SUB_MODEL_SERVICE from '_machina/service/SubModelService';
import DATASET from './Dataset';
import MODEL from './Model';

import { openFeatureDialog } from '../pages/view-model-page/dialogs/FeatureDialog';
import { openTargetDialog } from '../pages/view-model-page/dialogs/TargetDialog';
import DatasetOverridesModel from '../dialogs/dataset-wizard-dialog/DatasetOverridesModel';

export const StatusPreTraining = 0;
export const StatusTraining    = 5;
export const StatusCompleted   = 50;
export const StatusErrored     = 100;

export const EMPTY_CONFIG = "emptyConfig";

class ViewModel extends BaseModel {
  /**
   * @constructor
   */
   constructor() {
    super();
    this._setModel = null;
    this._setSubModels = null;
    this._setSelectedSubModel = null;
    this._setFeatures = null;
    this._setTarget = null;
    this._setTargetValue = null;
    this._setPredictionResult = null;
    this._setPredictionCount = null;
    this._setValues = null;    
    this._setPredictMode = null;
    this._setModelStatus = null;

    this._model = null;
    this._datasetOverridesModel = null;
    this._modelConfig = null;
    this._subModels = null;
    this._subModelsById = {};
    this._selectedSubModelId = null;
    this._predictMode = true;
    this._predictionCount = 0;

    this._features = null;
    this._featuresSorted = [];
    this._defaultValues = null;
    this._values = null;
    this._target = null;
    this._targetValue = null;
    this._timeSeriesDistribution = null;
    this._predictionResult = null;
    this._predictionDistribution = null;
    this._modelStatus = null;
    this._timerId = null;
  } 

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  getTimeSeriesDistribution() {
    return this._timeSeriesDistribution;
  }

  async _setTimeSeriesDistribution() {
    if (this._predictionDistribution) {
      for (let f in this._features) {        
        try {
          const colInfo = await this.getColumnInfo(f); 
          this._timeSeriesDistribution = colInfo.getTimeSeriesDistribution();
        } catch (e) {
          LOGGER.info(e);
        }
        break;
      }
    } 
  }

  openFeatureEditor(featureName) {
    const feature = this.getFeature(featureName);
    if (feature) {
      openFeatureDialog(feature);
    }
  }

  openTargetEditor() {
    if (this._target) {
      openTargetDialog(this._target);
    }
  }

  isPredictMode(mode) {
    return this._predictMode;
  }

  async switchModes(isPredictMode) {
    this.showStatus(null);
    try {
      this._predictMode = isPredictMode;

      // Reset values prior to switching modes
      this._features = null;
      this._featuresSorted = [];
      this._defaultValues = null;
      this._values = null;
      this._target = null;
      this._targetValue = null;
      this._predictionResult = null;
      this._predictionDistribution = null;
      this._timeSeriesDistribution = null;

      await this.selectSubModel(this._selectedSubModelId);                  
    } finally {
      // TODO: We always switch the model, even on an error
      // A better approach would be to collect all values in an object and swap
      // them all at once if it is successful.
      this._setPredictMode(this._predictMode);
      this.hideStatus();
    }
  }

  getOverrides() {
    return this._datasetOverridesModel;
  }

  getFeature(featureName) {
    if (!this._features) return null;
    return this._features[featureName];
  }
  
  getCurrentSubModelProblemType() {
    const subModel = this._subModelsById[this._selectedSubModelId];
    return subModel ? subModel.ProblemType : null;
  }

  getSubModels() {
    //console.log(this._subModels);
    if (!this._subModels) return [];

    const clone = [...this._subModels];
    clone.sort((a, b) => {
      const pmA = a.PrimaryMetric;
      const pmB = b.PrimaryMetric;
      return pmB - pmA;    
    })
    return clone;
  }

  _formatFloat(value) {
    if (value) {
      try {
        value = Math.round(value * 100) / 100;
      } catch {}
    }
    return value;
  }

  isTargetOptionsAvailable() {
    return !this.isPredictMode() && this._target?.level?.length > 0;
  }

  // isTargetDateTime() {
  //   return this._target?.level?.length > 0;
  // }

  setValue(featureName, value) {
    this._values[featureName] = value;

    // Notify view
    this._setValues({...this._values});
  }

  setTargetValue(value) {
    this._targetValue = value;

    // Notify view
    this._setTargetValue(value);
  }

  getTargetValue() {
    return this._targetValue;
  }

  getValue(featureName) {    
    let def = false;
    let value = this._values[featureName];
    if (!value) {
      value = this._defaultValues[featureName];
      def = true;
    }

    if (value) {
      return {
        isDefault: def,
        value: value
      }
    }
    return null;
  }

  getFeatures() {
    if (!this._featuresSorted) return [];
    return this._featuresSorted;
  }

  getTargetName() {
    if (this?._target?.name) {
      return this._target.name;
    }
    return null;
  }

  getPredictionDistribution() {
    return this._predictionDistribution;
  }

  getPredictionResult() {
    let v = this._predictionResult;
    try {
      const f = this._formatFloat(parseFloat(v));
      v = isNaN(f) ? v : f;
    } catch (e) {}

    return v !== null ? "" + v : "";
  }

  getSelectedSubModelId() {
    return this._selectedSubModelId;
  }

  async selectSubModel(id) {
    this.showStatus(null);
    try {
      const values = await SUB_MODEL_SERVICE.getValues(id);
      console.log("## VALUES:")
      console.log(values);

      const isPredict = this.isPredictMode();    
      
      this._selectedSubModelId = id;
      this._features = values.columns ? values.columns : {}
      this._target = values.target ? values.target : null;
      this._targetValue = null;
      this._defaultValues = {}
      this._values = {}
      this._featuresSorted = [];

      // Features
      for (let f in this._features) {
        const feat = this._features[f];
        feat.name = f;

        // sorted
        this._featuresSorted.push(feat);
        // values
        this._defaultValues[f] = isPredict ? "" + feat.value : "Not available"; // TODO: i18n
      }
  
      this._featuresSorted.sort((a, b) => {
        const pmA = a.importance;
        const pmB = b.importance;
        return pmB - pmA;    
      })      

      // Get prediction
      if (isPredict) {
        await this.predict();
      } else {
        if (this._target?.level?.length > 0) {
          this._targetValue = "" + this._target.level[0];
          await this.optimal();
        }
      }

      await this._setTimeSeriesDistribution();

      // Update view
      this._setSelectedSubModel(this._selectedSubModelId);
      this._setFeatures(this._features);
      this._setTarget(this._target);
      this._setTargetValue(this._targetValue);
      this._setPredictionResult(this._predictionResult);
      this._setValues(this._values);  
    } finally {
      this.hideStatus();
    }
  }

  async _loadSubModels(id, selectPrimary = false) {
    this.showStatus();
    try {
      // Load sub-models
      // TODO: What to do if there are no submodels?
      const subModels = await SUB_MODEL_SERVICE.findByModel(id); 
      
      console.log("### SUB MODELS:")
      console.log(subModels);

      // Determine current sub-model (based on primary metric)
      let maxMetric = -65536;
      let maxId = -1;
      const subModelsById = {}
      for (let i = 0; i < subModels.length; i++) {
        const s = subModels[i];
        subModelsById[s.ID] = s;
        if (s.PrimaryMetric > maxMetric) {
          maxMetric = s.PrimaryMetric;
          maxId = s.ID;
        }
      }

      // Load sub-model values
      if (selectPrimary && maxId >= 0) {
        await this.selectSubModel(maxId);
      }            

      this._subModels = subModels;
      this._subModelsById = subModelsById;

      // Update view
      this._setSubModels(this._subModels);
    } finally {
      this.hideStatus();
    }      
  }

  getStatus() {
    return this._modelStatus;
  }

  isRenderable() {
    return this._modelStatus && 
      (this._modelStatus.status > StatusPreTraining && this._modelStatus.steps > 0)
  };

  isStatusShown() {
    if (!this._modelStatus) return true;

    return !(
      (this._modelStatus.status === StatusCompleted) && 
      (this._modelStatus.trainingUpdate === this._modelStatus.startTime)
    )
  }

  _updateModelStatus(model) {
    const status = {
      errorMessage: model.ErrorMessage,
      status: model.Status,
      trainingUpdate: model.TrainingUpdate,
      steps: model.Steps,
      targetSteps: model.TargetSteps
    }

    if (this._modelStatus === null) {
      status.startTime = status.trainingUpdate;
      status.startStatus = status.status;
    } else {
      status.startTime = this._modelStatus.startTime;
      status.startStatus = this._modelStatus.startStatus;
    }

    if (this.modelStatus === null || (JSON.stringify(status) !== JSON.stringify(this._modelStatus))) {
      console.log("changed.");    
      this._modelStatus = status;

      // notify view
      this._setModelStatus(status);
    } else {
      console.log("not changed.");
    }

    // TODO: Define status constants
    // TODO: If switch from pre-training to training or finished, force refresh (with timeout of 0)
    if (status.status < StatusCompleted) {    
      this._timerId = setTimeout(async () => {      
        let succeeded = false;
        try {
          const updatedModel = await MODEL_SERVICE.get(model.ID); 
          if (status.startStatus < StatusTraining && updatedModel.Status >= StatusTraining) {
            console.log("Reloading model.");
            setTimeout(() => this.loadModel(model.ID), 0);
          } else {
            this._updateModelStatus(updatedModel);
          }
          succeeded = true;          
        } catch (e) {
          LOGGER.error("Error updating status", e);
        } finally {
          // reschedule on error
          if (!succeeded) {
            this._updateModelStatus(model);
          }
        }
      }, 10 * 1000 /* TODO: Make variable, setting, etc. */); 
    }
  }

  async getColumnInfo(columnName) {
    if (!this._modelConfig) {
      let modelId = null;
      try {
        modelId = this._model.ID;
      } catch (e) {
        LOGGER.info(e);
      }

      if (!modelId) {
        return null;
      }

      try {
        this._modelConfig = await MODEL.getModelConfiguration(modelId);
      } catch (e) {
        this._modelConfig = EMPTY_CONFIG;
        LOGGER.error("Error retrieving column info", e);
        return null;
      }
    }

    if (this._modelConfig === EMPTY_CONFIG) {
      return null;
    }

    return this._modelConfig.getColumns().getColumn(columnName);
  }

  async loadModel(id) {
    this.showStatus();
    try {
      // Reset
      this._reset();

      // Load model
      const model = await MODEL_SERVICE.get(id); 

      // Load sub-models
      // TODO: What to do if there are no submodels?
      try {
        await this._loadSubModels(id, true);
      } catch (e) {
        // This is a hack to handle errors that occur when there is a latency between a
        // submodel existing in the database and the files being written to cloud storage
        // TODO: Spend more time to find a proper solution
        LOGGER.error("Error reading sub-models", e);
      }
      
      // Attempt to load overrides
      this._datasetOverridesModel = new DatasetOverridesModel({});
      try {
        const overrides = await DATASET.getOverrides(model.DatasetID);
        this._datasetOverridesModel = 
          new DatasetOverridesModel({
            datasetOverrides: overrides
          });
      } catch (e) {
        LOGGER.error("Error reading overrides", e);
      }

      this._model = model;

      await this._setTimeSeriesDistribution();
 
      // Update view
      this._setModel(this._model);
      this._updateModelStatus(model);     
    } finally {
      this.hideStatus();
    }
  }

  getPredictionCount() {
    return this._predictionCount;
  }

  async predict() {
    this.showStatus(null);
    try {
      if (!this._features) throw Error("No features available.");
      if (!this._selectedSubModelId) throw Error("A sub model has not been selected.");

      const values = {}
      for (let n in this._features) {
        let f = this._features[n];
        let v = this.getValue(n).value;

        if (f.type === 'numerical') {
          try {
            const f = parseFloat(v);
            v = isNaN(f) ? v : f;
          } catch {}
        }

        values[n] = v;
      }

      console.log("## PREDICT VALUES:")
      console.log(values)

      const result = await SUB_MODEL_SERVICE.predict(this._selectedSubModelId, values);
      console.log("## RESULT")
      console.log(result)
      if (result.results !== null) {
        this._predictionResult = "" + result.results;
        this._predictionDistribution = result?.tsDistribution;
        this._predictionCount++;

        // Notify view        
        this._setPredictionCount(this._predictionCount);
        this._setPredictionResult(this._predictionResult);
      }      
    } finally {
      this.hideStatus();
    }
  }

  async optimal() {
    this.showStatus(null);
    try {
      if (!this._targetValue) throw Error("No target value specified.");
      if (!this._selectedSubModelId) throw Error("A sub model has not been selected.");

      const result = await SUB_MODEL_SERVICE.optimal(this._selectedSubModelId, this._targetValue); // token

      console.log("## OPTIMAL VALUES:");      
      console.log(result);

      // Values
      for (let f in result) {
        this._defaultValues[f] = "" + result[f].value;
      }

      // Notify
      // TODO: This is a hack, because we are only setting default values
      this._setValues({});
    } finally {
      this.hideStatus();
    }
  }

  stopTimer() {    
    if (this._timerId !== null) {      
      console.log("stopping timer.");
      clearTimeout(this._timerId);
      this._timerId = null;
    }
  }

  _reset() {
    this.stopTimer();
    this._model = null;
    this._datasetOverridesModel = null;
    this._modelConfig = null;
    this._subModels = null;
    this._subModelsById = {}
    this._selectedSubModelId = null;
    this._features = null;
    this._featuresSorted = [];
    this._defaultValues = null;
    this._values = null;
    this._target = null;
    this._targetValue = null;
    this._predictionResult = null;
    this._predictionDistribution = null;
    this._timeSeriesDistribution = null;
    this._predictionCount = 0;
    this._predictMode = true;
    this._modelStatus = null;
  }
}

// Singleton
const VIEW_MODEL = new ViewModel();

export default VIEW_MODEL;
