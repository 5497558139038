import { Card, CardContent, Grid } from '@mui/material';
import RectangularSkeleton from '_machina/react/components/common/skeleton/RectangularSkeleton';
import TextSkeleton from '_machina/react/components/common/skeleton/TextSkeleton';

const MiniChartSkeleton = () => (
    <Card>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs zeroMinWidth>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextSkeleton />
                                </Grid>
                                <Grid item xs={12}>
                                    <RectangularSkeleton height={20} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <RectangularSkeleton height={215} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default MiniChartSkeleton;
