import Gradient from "javascript-color-gradient";

/**
 * converts a number value to a color
 * @param {float} number The number to be converted to a color
 * @returns the color hexCode
 */
const colorAssigner = (number) => {
    number = 100 * number;
    if (number <= 1) {
        number = 1;
    }

    const colorArr = new Gradient()
    // .setColorGradient("#FF0000", "FFFF00", "00FF0E")    
    .setColorGradient("FF6200", "FFFF00", "00FF0E")
    .setMidpoint(100)
    const color = colorArr.getColor(number);
    return color
}

export default colorAssigner;