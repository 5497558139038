import CONFIG from '../config/Config';

// Logging levels supported
const LOG_LEVELS = {
  Error: 0,
  Warn: 1,
  Info: 2,
  Trace: 3,
};

/**
 * Logs messages to the console
 */
class Logger {
  /**
   * @constructor
   */
  constructor() {
    // The current logging level
    this.level = CONFIG.getLoggingLevel();
  }

  /**
   * Sets the logging level
   * @param {number} level The logging level
   */
  setLevel(level) {
    this.level = level;
  }

  /**
   * Returns the current logging level
   * @returns {number} The current logging level
   */
  getLevel() {
    return this.level;
  }

  /**
   * Attempt to log an info message
   * @param {string} message The message
   * @returns Whether the message was logged (based on log level)
   */
  info(message) {
    if (this.level < LOG_LEVELS.Info) {
      return false;
    }
    console.info(message);
    return true;
  }

  /**
   * Attempt to log an error message
   * @param {string} message The message
   * @param {exception} exception The exception that was raised
   * @returns Whether the message was logged (based on log level)
   */
  error(message, exception) {
    if (exception) {
      console.error(`${message}: ${exception}`);
    } else {
      console.error(message);
    }
    return true;
  }

  /**
   * Attempt to log a warning message
   * @param {string} message The message
   * @returns Whether the message was logged (based on log level)
   */
  warn(message) {
    if (this.level < LOG_LEVELS.Warn) {
      return false;
    }
    console.warn(message);
    return true;
  }

  /**
   * Attempt to log a trace message
   * @param {string} message The message
   * @returns Whether the message was logged (based on log level)
   */
  trace(message) {
    if (this.level < LOG_LEVELS.Trace) {
      return false;
    }
    console.info(message);
    return true;
  }
}

const LOGGER = new Logger();

export { LOGGER, LOG_LEVELS };
