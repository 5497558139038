import { FC, useMemo, useRef, useState } from 'react';
import { Sankey as ReavizSankey, SankeyLink } from 'reaviz';
import { SankeyNode, SankeyNodeData } from './SankeyNode/SankeyNode.tsx';
import { COLOR_SCHEME, buildSankeyNodes } from './utils.ts';
import { SankeyMenu, SankeyMenuOption } from './SankeyMenu/SankeyMenu.tsx';
import { ReactComponent as EmptyVector } from './empty.svg';
import css from './Sankey.module.css';

export interface SankeyProps {
  /**
   * The nodes to render in the sankey.
   */
  nodes: SankeyNodeData[];

  /**
   * The selected node option id.
   */
  selection?: string;

  /**
   * Height of the chart.
   */
  height?: number;

  /**
   * Width of the chart.
   */
  width?: number;

  /**
   * The options to display in the menu.
   */
  options?: SankeyMenuOption[];

  /**
   * When a menu option changes.
   */
  onMenuChange?: (option: SankeyMenuOption) => void;
}

export const Sankey: FC<SankeyProps> = ({
  nodes,
  selection,
  height,
  width,
  options,
  onMenuChange
}) => {
  const emptyRef = useRef<HTMLHeadingElement | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [sankeyNodes, sankeyLinks] = useMemo(() => buildSankeyNodes(nodes, selection), [nodes, selection]);

  // This is a hack to make the sankey look better
  // when there are a lot of nodes or not many
  const nodePadding = sankeyNodes.length > 10 ? 25 : 40;

  if (sankeyNodes.length <= 2 || !selection) {
    const isEmpty = sankeyNodes.length <= 1;

    let message;
    let messageNote = `Let's find another attribute ▾`;
    if (isEmpty) {
      message = 'No data to visualize';
    } else if (sankeyNodes.length === 2) {
      message = 'This visualization requires at least 2 important features';
    } else if (!selection) {
      message = 'No attribute selected'
      messageNote = `Select an attribute ▾`
    }
  
    return (
      <div className={css.empty}>
        <EmptyVector />
        <h3>
          {message}
        </h3>
        {/* <p
          className={css.emptyBtn}
          ref={emptyRef}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {messageNote}
        </p>
        {options.length > 0 && (
          <SankeyMenu
            reference={emptyRef}
            options={options}
            open={menuOpen}
            selection={selection}
            onMenuChange={onMenuChange}
            onMenuClose={() => setMenuOpen(false)}
          />
        )} */}
      </div>
    );
  }

  return (
    <ReavizSankey
      height={height}
      width={width}
      justification="justify"
      colorScheme={COLOR_SCHEME}
      nodePadding={nodePadding}
      nodeWidth={1}
      className={css.sankey}
      nodes={sankeyNodes.map((node, i) => (
        <SankeyNode
          key={`node-${i}`}
          nodeCount={sankeyNodes.length}
          index={i}
          options={options}
          selection={selection}
          onMenuChange={onMenuChange}
          {...node}
          title={i}
        />
      ))}
      links={sankeyLinks.map((link, i) => (
        <SankeyLink
          key={`link-${i}`}
          {...link}
          tooltip={null}          
          opacity={(active, disabled) => active || !disabled ? 1 : disabled ? 0.2 : 0.9}
        />
      ))}
    />
  );
};
