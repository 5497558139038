import * as React from 'react';

import { useTheme } from '@mui/material/styles';

import {
  Box,
  Typography,
} from '@mui/material';

// assets
import CommonTooltip from './CommonTooltip';

export default function CommonLabeledContent({ title, description, subTitle, content, children }) {
  const theme = useTheme();

  let body = children;
  if (!body) {
    body = (
      <>
        <Typography
          variant="subtitle1"
          sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
        >
          {title}
        </Typography>
        {subTitle &&
          <Typography variant="caption">{subTitle}</Typography>
        }
      </>
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CommonTooltip title={description} placement={"right-start"}>
        <Box>
            {content}
        </Box>
      </CommonTooltip>
      <Box sx={{ marginLeft: '10px' }}>
        {body}
      </Box>
    </Box >
  );
}