import * as React from 'react';

import { useTheme } from '@mui/material/styles';

import {
  Box,
  Typography,
} from '@mui/material';

// assets
import Avatar from 'ui-component/extended/Avatar';
import CommonTooltip from './CommonTooltip';

export default function CommonLabeledImage({ avatarMode, title, description, subTitle, imageUrl, defaultImageUrl, rightComponent, children }) {
  const [img, setImg] = React.useState(null);
  const theme = useTheme();

  React.useEffect(() => {
    const tempImage = new Image();
    tempImage.onload = (e) => {
      setImg(e.target.src)
    }
    tempImage.onerror = (e) => {
      setImg(defaultImageUrl);
    }
    tempImage.src = imageUrl;
  }, [imageUrl, defaultImageUrl, setImg])

  const props = {}
  if (!avatarMode) props.variant = 'rounded';
  const content = !avatarMode ? "" : null;

  const sx = {}
  if (!avatarMode) sx.backgroundColor = 'transparent';
  
  let body = children;
  if (!body) {
    body = (
      <>
        <Typography
          variant="subtitle1"
          sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
        >
          {title}
        </Typography>
        {subTitle &&
          <Typography variant="caption">{subTitle}</Typography>
        }
      </>
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CommonTooltip title={description} placement={"right-start"}>
        <Box>
          <Avatar
            src={img || imageUrl || defaultImageUrl}
            {...props}
            sx={sx}
          >
            {content}
          </Avatar>
        </Box>
      </CommonTooltip>
      <Box sx={{ marginLeft: '16px' }}>
        {body}
      </Box>
      {rightComponent &&
        <Box sx={{ 
          marginLeft: '6px', 
          display: 'flex',
          alignItems: 'center'
        }}>
          {rightComponent}
        </Box>
      }
    </Box >
  );
}