// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconChartBar, IconBrain, IconSchool } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'machina',
    title: "Machina" /*<FormattedMessage id="others" />*/,
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: IconChartBar,
            breadcrumbs: true
        },
        {
            id: 'datasets',
            title: <FormattedMessage id="datasets" />,
            type: 'item',
            url: '/datasets',
            icon: icons.IconSitemap,
            breadcrumbs: false
        },
        {
            id: 'models',
            title: <FormattedMessage id="models" />,
            type: 'item',
            url: '/models',
            icon: IconBrain,
            breadcrumbs: false
        },        
        {
            id: 'training',
            title: <FormattedMessage id="training" />,
            type: 'item',
            url: '/training',
            icon: IconSchool,
            breadcrumbs: false
        }
        // {
        //     id: 'documentation',
        //     title: <FormattedMessage id="documentation" />,
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/',
        //     icon: icons.IconHelp,
        //     external: true,
        //     target: true
        // }
        // {
        //     id: 'roadmap',
        //     title: <FormattedMessage id="roadmap" />,
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/roadmap',
        //     icon: icons.IconSitemap,
        //     external: true,
        //     target: true
        // }
    ]
};

export default other;
