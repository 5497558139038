import {
    Tooltip,
} from '@mui/material';

export default function CommonTooltip({title, children, ...other}) {
    const MAX_TIP = 256;
    let tip = title;
    if (tip && tip.length > MAX_TIP) {
        tip = tip.substring(0, MAX_TIP) + "...";
    }

    if (!title) {
        return <>{children}</>;
    }

    return (
        <Tooltip title={tip} enterDelay={500} enterNextDelay={500} disableInteractive={true} {...other}>
            {children}
        </Tooltip>
    )
}