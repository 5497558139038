import React from 'react';

import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

let _setConfirmProps = null;
let _setOpen = null;

function openConfirmDialog(title, message, callback) {
    _setConfirmProps({
        title: title,
        message: message,
        callback: callback
    })
    _setOpen(true);
}

const ConfirmDialog = (props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [confirmProps, setConfirmProps] = React.useState({
    title: "Title",
    message: "Message",
    callback: () => {}
  });    
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));  

  _setConfirmProps = setConfirmProps;
  _setOpen = setOpen;

  return (
    <Dialog        
      open={isOpen}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
    >
      <DialogTitle>{confirmProps.title}</DialogTitle>
      <DialogContent>{confirmProps.message}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            confirmProps.callback();
          }}
        >
          <FormattedMessage id="yes" />
        </Button>
        <Button
          onClick={() => setOpen(false)}
        >
          <FormattedMessage id="no" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDialog, openConfirmDialog };