import React from 'react';

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

import STRING_UTIL from '_machina/util/StringUtil';
import { CommonDialog, CommonDialogTabPanel } from '_machina/react/components/common/dialog/CommonDialog';
import VIEW_MODEL from '_machina/react/model/ViewModel';

export function BaseDialogInner({ height, title, callback, open, children, maxWidth }) {
  const [tab, setTab] = React.useState("one");

  return (
    <CommonDialog
      title={title}
      open={open}
      callback={callback}
      tab={tab}
      setTab={setTab}
      fullWidth={true}
      maxWidth={maxWidth ? maxWidth : "xs"}
      height={height ? height : 200}
      tabs={[{
        value: "one",
        label: "nada",
      }]}
      tabPanels={
        open && (
          <>
            <CommonDialogTabPanel tab={tab} value={"one"}>
              {children}
            </CommonDialogTabPanel>
          </>
        )}
    />
  );
}

export function SelectType({ title, value, setValue, opts, placeholderValue, feature }) {
  const options = []
  let lastValue = null;
  let allNumbers = true;
  let foundPlaceholder = false;

  // Check is just dates (no time)
  const onlyDates = STRING_UTIL.isOnlyDates(opts);

  // Check for all numbers
  for (let i = 0; i < opts.length; i++) {
    const v = opts[i];
    try {
      const n = parseFloat(v);
      if (isNaN(n)) {
        allNumbers = false;
      }
    } catch (e) {
      allNumbers = false;
    }

    if (v === lastValue) continue;
    if (v ===placeholderValue) {
      foundPlaceholder = true;
    }

    lastValue = v;
    options.push({ 
      value: v, 
      name: VIEW_MODEL.getOverrides().getColumnValueAlias(feature.name,
        onlyDates ? STRING_UTIL.stripTime(v) : STRING_UTIL.removeDupedDashes(v))
    })
  }

  if (!foundPlaceholder && placeholderValue && placeholderValue.length > 0) {
    options.push({ 
      value: placeholderValue, 
      name: VIEW_MODEL.getOverrides().getColumnValueAlias(feature.name,
        onlyDates ? STRING_UTIL.stripTime(placeholderValue) : STRING_UTIL.removeDupedDashes(placeholderValue)) 
    })
  }

  options.sort((a, b) => {
    const pmA = a.name;
    const pmB = b.name;
    return allNumbers ? pmA - pmB : pmA.localeCompare(pmB);
  })    
  
  // TODO: Move the select and label portion to common
  return (    
    <FormControl variant="outlined" sx={{ m: 0, minWidth: 200, maxWidth: '93%' }}>
      <>
        <InputLabel>{title}</InputLabel>
        <Select
            MenuProps={{
              disableScrollLock: true,
            }}
          autoWidth            
          value={value && value.length > 0 ? value : placeholderValue}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          label={title}
        >
          {options ?
            options.map(menuItem => {
              return (
                <MenuItem sx={{minWidth: '200px'}} key={menuItem.value} value={menuItem.value}>{menuItem.name}</MenuItem>
              )
            }) : null
          }
        </Select>
      </>
    </FormControl>
  );
}
