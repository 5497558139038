import { Card, List, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import RectangularSkeleton from '_machina/react/components/common/skeleton/RectangularSkeleton';
import TextSkeleton from '_machina/react/components/common/skeleton/TextSkeleton';

// ==============================|| SKELETON - TOTAL INCOME DARK/LIGHT CARD ||============================== //

const TitleSkeleton = () => (
    <Card sx={{ px: 2, py: 1.3 }}>
        <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0}}>
                <ListItemAvatar>
                    <RectangularSkeleton width={36} height={36} />
                </ListItemAvatar>
                <ListItemText
                    sx={{ py: 0 }}
                    primary={<RectangularSkeleton height={20} />}
                    secondary={<TextSkeleton />}
                />
            </ListItem>
        </List>
    </Card>
);

export default TitleSkeleton;