import other from './other';
import admin from './admin';
import developer from './developer';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [/*pages,*/ other, developer, admin]
};

export default menuItems;
