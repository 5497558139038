import React from 'react';

import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import Transitions from 'ui-component/extended/Transitions';
import { LOGGER } from '_machina/util/Logging';
import { uuidv4 } from '_machina/util/Uuid';
import CommonImageChooser from '_machina/react/components/common/fields/CommonImageChooser';
import CommonPasswordField from '_machina/react/components/common/fields/CommonPasswordField';
import CommonTextField from '_machina/react/components/common/fields/CommonTextField';
import { CommonWizard, CommonWizardStepPanel } from '_machina/react/components/common/wizard/CommonWizard';
import Validator from '_machina/react/components/common/validation/Validator';
import { useForceUpdate } from '_machina/react/Util';

import APP from '_machina/react/model/App';
import USER from '_machina/react/model/User';
import USER_LIST from '_machina/react/model/UserList';

const validator = new Validator();

let _setUniqueId = null;
let _setOpen = null;
let _chooserState = null;

const setChooserState = (state) => {
    _chooserState = state;
}

const getChooserState = () => {
    return _chooserState;
}

export function openUserWizard() {
  _chooserState = null;
  _setUniqueId(uuidv4());
  _setOpen(true);
}

export default function UserWizardDialog() {
  const [uniqueId, setUniqueId] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  _setUniqueId = setUniqueId;
  _setOpen = setOpen;

  if (!open) return;

  return (
    <UserWizardDialogInner
      key={uniqueId}
      open={open}
      setOpen={setOpen}
    />
  )
}

const userInfoStep = {
  key: "userInfo",
  title: <FormattedMessage id="userInformation" />,
  onNext: (state, setState, forceUpdate) => {
    validator.reset();
    validator.checkMinLength(0, "name", state.user.Name);
    validator.checkValidEmail(0, "email", state.user.Email);
    if (validator.getMinInvalidTab() >= 0) {
      forceUpdate();
      return false;
    }
    return true;
  },
  isEnabled: (state) => { return true; }
}

const passwordStep = {
  key: "password",
  title: <FormattedMessage id="password" />,
  onNext: (state, setState, forceUpdate) => {
    validator.reset();
    validator.checkMinLength(0, "password", state.user.Password);
    validator.checkMinLength(0, "confirm", state.user.ConfirmPassword);
    if (validator.getMinInvalidTab() < 0) {
      if (state.user.Password !== state.user.ConfirmPassword) {
        validator.checkMinLength(0, "password", "");
        validator.checkMinLength(0, "confirm", "");
      }
    }
    if (validator.getMinInvalidTab() >= 0) {
      forceUpdate();
      return false;
    }
    return true;
  },
  isEnabled: (state) => { return true; }
}

const STEPS = [userInfoStep, passwordStep]

function UserWizardDialogInner({ open, setOpen }) {
  const [step, setStep] = React.useState(0);
  const [state, setState] = React.useState({
    user: {
      Name: "",
      Email: "",
      Password: "",
      ConfirmPassword: ""
    },
  });
  const forceUpdate = useForceUpdate();

  const onFinish = () => {
    (async () => {
      let succeeded = false;
      try {
        await USER.createUser(state.user);
        succeeded = true;
      } catch (e) {
        LOGGER.error('Error creating user', e);
        if (e.toString().indexOf("user with the provided email already exists") !== -1) {
          APP.showErrorMessage(<FormattedMessage id="error.users.create.alreadyExists" />);
        } else if (e.toString().indexOf("password must be ") !== -1) {
          APP.showErrorMessage(<FormattedMessage id="error.users.create.passwordComplexity" />);
        } else {
          APP.showErrorMessage(<FormattedMessage id="error.users.create" />);
        }
      }
      if (succeeded) {
        // Force dataset list to refresh
        USER_LIST.getUsers();
        setOpen(false);
      }
    })();
  }

  const onCancel = () => {
    return true;
  }

  return (
    <CommonWizard
      title={<FormattedMessage id="createUser" />}
      onCancel={onCancel}
      onFinish={onFinish}
      open={open}
      setOpen={setOpen}
      step={step}
      setStep={setStep}
      steps={STEPS}
      stepsInfo={forceUpdate}
      state={state}
      setState={setState}
      stepPanels={
        open && (
          <>
            <CommonWizardStepPanel value={step} steps={STEPS} stepKey={userInfoStep.key} >

              {(STEPS[step].key === userInfoStep.key) &&
                <Transitions type="fade" in={true}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                      <Typography>
                        <FormattedMessage id="wizard.user.info.header" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CommonTextField
                            label={<FormattedMessage id="name" />}
                            value={state.user.Name}
                            onChange={(e) => {
                              state.user.Name = e.target.value;
                              setState({ ...state })
                            }}
                            required
                            helperText={
                              !validator.isValid(0, "name")
                                ? <FormattedMessage id="validation.name" /> : null
                            }
                            error={!validator.isValid(0, "name")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CommonTextField
                            label={<FormattedMessage id="email" />}
                            value={state.user.Email}
                            onChange={(e) => {
                              state.user.Email = e.target.value;
                              setState({ ...state })
                            }}
                            required
                            helperText={
                              !validator.isValid(0, "email")
                                ? <FormattedMessage id="validation.email" /> : null
                            }
                            error={!validator.isValid(0, "email")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CommonImageChooser
                            avatarMode={true}
                            width={120}
                            justifyContent="center"
                            currentImage={state.user.ImageURL}
                            onSetImage={(image) => {
                              state.user.ImageURL = image;
                              setState({ ...state })
                            }}
                            getChooserState={getChooserState}
                            setChooserState={setChooserState}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Transitions>
              }
            </CommonWizardStepPanel>
            <CommonWizardStepPanel value={step} steps={STEPS} stepKey={passwordStep.key} >
              {(STEPS[step].key === passwordStep.key) &&
                <Transitions type="fade" in={true}>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                      <Typography>
                        <FormattedMessage id="wizard.user.password.header" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CommonPasswordField
                            label={<FormattedMessage id="password" />}
                            value={state.user.Password}
                            onChange={(e) => {
                              state.user.Password = e.target.value;
                              setState({ ...state })
                            }}
                            required
                            helperText={
                              !validator.isValid(0, "password")
                                ? state.user.Password.length === 0 ?
                                  <FormattedMessage id="validation.password" /> :
                                  <FormattedMessage id="validation.passwordMismatch" />
                                : null
                            }
                            error={!validator.isValid(0, "password")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CommonPasswordField
                            label={<FormattedMessage id="confirm" />}
                            value={state.user.ConfirmPassword}
                            onChange={(e) => {
                              state.user.ConfirmPassword = e.target.value;
                              setState({ ...state })
                            }}
                            required
                            helperText={
                              !validator.isValid(0, "confirm")
                                ? state.user.Password.length === 0 ?
                                  <FormattedMessage id="validation.confirmPassword" /> :
                                  <FormattedMessage id="validation.passwordMismatch" />
                                : null
                            }
                            error={!validator.isValid(0, "confirm")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Transitions>
              }
            </CommonWizardStepPanel>
          </>
        )
      }
    />
  );
}
