import React from 'react';

import { ConfirmDialog } from '../components/common/dialog/ConfirmDialog';
import DatasetEditDialog from './dataset-edit-dialog';
import DatasetWizardDialog from './dataset-wizard-dialog';
import ModelWizardDialog from './model-wizard-dialog';
import ModelEditDialog from "./model-edit-dialog";
import UserEditDialog from "./user-edit-dialog";
import UserWizardDialog from './user-wizard-dialog';
import BusyScreen from '../BusyScreen';
import TextToImageDialog from '../components/common/fields/image/TextToImageDialog';

export default function Dialogs() {
    return (
        <>
            <ConfirmDialog />
            <DatasetEditDialog/>
            <UserEditDialog/>
            <UserWizardDialog/>
            <ModelWizardDialog/>
            <ModelEditDialog/>
            <DatasetWizardDialog/>
            <BusyScreen/>
            <TextToImageDialog/>
        </>
    )
}