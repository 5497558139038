import BaseModel from './BaseModel';
import WORKFLOW_SERIVCE from '_machina/service/WorkflowService';

class Workflow extends BaseModel {
//   /**
//    * @constructor
//    */
//   constructor() {
//     super();
//   }
  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  async importDataset(importJson) {
    this.showStatus();
    try {
        const result = await WORKFLOW_SERIVCE.importDataset(importJson);
        // console.log(result);
        return result;
    } finally {
      this.hideStatus();
    }
  }

  async train(trainJson) {
    this.showStatus();
    try {
        const result = await WORKFLOW_SERIVCE.train(trainJson);
        // console.log(result);
        return result;
    } finally {
      this.hideStatus();
    }
  }

  _reset() {
  }  
}

// Singleton
const WORKFLOW = new Workflow();

export default WORKFLOW;
