import React from "react";

import { Grid } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

import { Sankey } from '../../../../../goodcode/Sankey/Sankey.tsx'
import STRING_UTIL from "_machina/util/StringUtil";
import VIEW_MODEL from "_machina/react/model/ViewModel";

export default function SankeyChart() {
  const features = VIEW_MODEL.getFeatures();

  const nodes = []
  for (let i = 0; i < features.length && i <= 8; i++) {
    const f = features[i];
    nodes.push({
      id: i.toString(),
      category: VIEW_MODEL.getOverrides().getColumnAlias(f.name),
      label: VIEW_MODEL.getOverrides().getColumnValueAlias(
        f.name, VIEW_MODEL.getValue(f.name).value),
      value: f.importance,
    });
  }

  nodes.push({
    id: 'target',
    label: VIEW_MODEL.getOverrides().getColumnValueAlias(
      VIEW_MODEL.getTargetName(),     
      STRING_UTIL.removeDupedDashes(
        VIEW_MODEL.isPredictMode() ? VIEW_MODEL.getPredictionResult() : VIEW_MODEL.getTargetValue()
      )
    ),
    value: 1337,
    category: VIEW_MODEL.getOverrides().getColumnAlias(VIEW_MODEL.getTargetName())
  });

  return (
    <MainCard title={null}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ paddingLeft: '5px', paddingRight: '5px', overflow: "auto" }}>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
              <Sankey
                height={200}
                width={"60%"}
                selection="target"
                nodes={nodes}
              ></Sankey>
            </div>
          </div>
        </Grid>
      </Grid>
    </MainCard>
  );
}