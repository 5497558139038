import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';
import CommonTextField from './CommonTextField';

/**
 * Common password field used throughout the application
 * @returns Password field content
 */
export default function CommonPasswordField(props) {
  const { sx, ...other } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <CommonTextField
      type={showPassword ? 'text' : 'password'}
      sx={sx}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}