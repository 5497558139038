import React from 'react';

import { Typography, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import Transitions from 'ui-component/extended/Transitions';

import { CommonWizardStepPanel } from '_machina/react/components/common/wizard/CommonWizard';

import ModelTargetsComponent from '../targets/ModelTargetsComponent';
import FileAnalysisModel from '../FileAnalysisModel';

export const createModelTargetsStep = (validator, title) => {
  return {
    key: "modelTargets",
    title: title || <FormattedMessage id="modelTargets" />,
    onNext: (state, setState, forceUpdate) => {
      validator.reset();
      const enabledCount = new FileAnalysisModel(state?.dataset?.AnalysisResults).getColumns().getEnabledPredictionColumnsCount();
      if (enabledCount < 1) {
        validator.checkMinLength(0, "modelTargetsSelected", "");
      }
      if (validator.getMinInvalidTab() >= 0) {
        forceUpdate();
        return false;
      }
      return true;
    },
    onPrev: () => { validator.reset() },
    isEnabled: (state) => { return state.trainModel; }
  }
}

export function ModelTargetsStep({ modelTargetsStep, step, steps, state, setState, validator }) {
  return (
    <CommonWizardStepPanel value={step} steps={steps} stepKey={modelTargetsStep.key}>
      {(steps[step].key === modelTargetsStep.key) &&
        <Transitions type="fade" in={true}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <Typography>
                <FormattedMessage id="wizard.dataset.modelTargets.header" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ModelTargetsComponent
                state={state}
                setState={setState}
                validator={validator}
              />
            </Grid>
          </Grid>
        </Transitions>
      }
    </CommonWizardStepPanel>
  );
}
