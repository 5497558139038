import { useTheme } from "@mui/system";
import { FormattedMessage } from 'react-intl';

import {Grid, Typography} from '@mui/material';

import FileStatus from '../FileStatus';

export default function CommonModelComponent({ 
    validator,
    fileModel,
    columnsComponent,
    chartComponent,
    statusComponent
}) {
    const theme = useTheme();

    let validationMessage = null;
    if (validator) {
        if (!validator.isValid(0, "modelFeaturesSelected")) {
            validationMessage = 
                <Typography sx={{color: theme.palette.error.main}}>
                    <FormattedMessage id="wizard.dataset.modelFeaturesValidation"/>                    
                </Typography>
        } else if (!validator.isValid(0, "modelTargetsSelected")) {
            validationMessage = 
                <Typography sx={{color: theme.palette.error.main}}>
                    <FormattedMessage id="wizard.dataset.modelTargetsValidation"/>                                         
                </Typography>
        }
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    {columnsComponent}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {chartComponent}
                        </Grid>
                        <Grid item xs={12}>
                            {statusComponent}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{mt: -1}}>
                    {validationMessage !== null && validationMessage}
                    {validationMessage === null && <FileStatus fileModel={fileModel} />}
                </Grid>
            </Grid>
        </>
    )
}