import { FormattedMessage } from 'react-intl';

import Transitions from "ui-component/extended/Transitions";

import CommonInfoBox from "_machina/react/components/common/CommonInfoBox";
import { CommonInfoBoxRow  } from "_machina/react/components/common/CommonInfoBox";
import FileStatus from "./FileStatus";

export default function FileDetailsComponent({ fileModel }) {    
    const totalRows = fileModel.getTotalRows();
    return (
        <Transitions type="fade" in={true} key={fileModel.getStoragePath()}>
            <CommonInfoBox sx={{ maxWidth: 450 }}>
                <CommonInfoBoxRow
                    name={<><FormattedMessage id="status"/>:</>}
                    value={
                        <FileStatus fileModel={fileModel} />
                    }
                />
                <CommonInfoBoxRow
                    name={<><FormattedMessage id="columns"/>:</>}
                    value={fileModel.getTotalColumns()}
                />
                {totalRows >= 0 &&
                    <CommonInfoBoxRow
                        name={<><FormattedMessage id="rows"/>:</>}
                        value={totalRows}
                    />
                }
            </CommonInfoBox>
        </Transitions>
    );
}


