import BaseModel from './BaseModel';
import DATASET_SERVICE from '_machina/service/DatasetService';
import { LOGGER } from '_machina/util/Logging';

import { openDatasetEditDialog } from '../dialogs/dataset-edit-dialog';

class Dataset extends BaseModel {
//   /**
//    * @constructor
//    */
//   constructor() {
//     super();
//   }
  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  async uploadDatasetFile(file) {
    this.showStatus();
    try {
       return await DATASET_SERVICE.upload(file);
    } finally {
      this.hideStatus();
    }
  }  

  async analyzeFile(fileUri, columns, excludedColumns) {
    this.showStatus();
    try {
        return await DATASET_SERVICE.analyze(fileUri, columns, excludedColumns);
    } finally {
      this.hideStatus();
    }
  }  

  async previewFile(fileUri, rows = 100) {
    this.showStatus();
    try {
        return await DATASET_SERVICE.preview(fileUri, rows);
    } finally {
      this.hideStatus();
    }
  }  

  async uniqueValues(fileUri, column, rows = 100) {
    this.showStatus();
    try {
        return await DATASET_SERVICE.uniqueValues(fileUri, column, rows);
    } finally {
      this.hideStatus();
    }
  }  

  async showDatasetEditDialog(id, callback) {
    this.showStatus();
    try {
      const result = await DATASET_SERVICE.get(id );            
      await openDatasetEditDialog(result, {cb: callback});
    } finally {
      this.hideStatus();
    }
  }   

  async updateDataset(dataset) {
    this.showStatus();
    try {
        await DATASET_SERVICE.update(dataset)
    } finally {
      this.hideStatus();
    }
  }

  async getOverrides(id) {
    this.showStatus();
    try {
        return await DATASET_SERVICE.getOverrides(id)
    } finally {
      this.hideStatus();
    }
  }

  isPrivate(dataset) {
    return !dataset.Public && dataset.UserID;
  }

  _reset() {
  }  
}

// Singleton
const DATASET = new Dataset();

export default DATASET;
