import { LOGGER } from "./Logging";

class Storage {
  constructor() {
    this.initialized = false;
    this.localStorageAvailable = false;
  }

  OBJECT_MARKER = "_obj_:";

  init() {
    if (this.initialized) return;
    this.initialized = true;
    this.localStorageAvailable = this.checkLocalStorageAvailable();
  }

  checkLocalStorageAvailable() {
    const TEST = '__test__';
    try {
      localStorage.setItem(TEST, TEST);
      localStorage.removeItem(TEST);
      LOGGER.info("Local storage is available.");
      return true;
    } catch (e) {
      LOGGER.info("Local storage is not available.");
    }
    return false;
  }

  put(name, value) {
    this.init();

    if (this.localStorageAvailable) {
      if (typeof value === 'object') {
        value = this.OBJECT_MARKER + JSON.stringify(value);
      }
      localStorage.setItem(name, value);
      return true;
    }

    LOGGER.info("Unable to perform put, storage not available.")
    return false;
  }

  get(name) {    
    this.init();

    if (this.localStorageAvailable) {
      let value = localStorage.getItem(name);
      if (value.startsWith(this.OBJECT_MARKER)) {
        value = JSON.parse(value.substring(this.OBJECT_MARKER.length));
      }
      return value;
    }

    LOGGER.info("Unable to perform get, storage not available.")
    return null;
  }

  remove(name) {
    this.init();

    if (this.localStorageAvailable) {
      localStorage.removeItem(name);
      return true;
    }

    LOGGER.info("Unable to perform remove, storage not available.")
    return false;
  }
}

const STORAGE = new Storage();

export default STORAGE;