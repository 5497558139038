import * as React from 'react';
import { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import {
    TableCell,
} from '@mui/material';

import { CircularProgress } from "@mui/material"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { LinearProgress } from '@mui/material';

import { useNavigate } from "react-router-dom";

import { getDefaultModelUrl } from '_machina/assets/images';
import { LOGGER } from '_machina/util/Logging';
import APP from '_machina/react/model/App';
import MODEL from '_machina/react/model/Model';
import MODEL_LIST from '_machina/react/model/ModelList';
import CommonLabeledContent from '_machina/react/components/common/CommonLabeledContent';
import CommonLabeledImage from '_machina/react/components/common/CommonLabeledImage';
import CommonPageHeader from '_machina/react/components/common/CommonPageHeader';
import CommonTable from '_machina/react/components/common/table/CommonTable';
import CommonTableEditColumn from '_machina/react/components/common/table/CommonTableEditColumn';
import CommonTableViewColumn from '_machina/react/components/common/table/CommonTableViewColumn';
import * as ViewModel from '_machina/react/model/ViewModel';
import TableSkeleton from '_machina/react/components/common/skeleton/TableSkeleton';
import LabeledImageSkeleton from '_machina/react/components/common/skeleton/LabeledImageSkeleton';
import TextSkeleton from '_machina/react/components/common/skeleton/TextSkeleton';
import TableIconButtonSkeleton from '_machina/react/components/common/skeleton/TableIconButtonSkeleton';
import PrivateObjectIcon from '_machina/react/components/common/icons/PrivateObjectIcon';

const TABLE_NAME = "training-table";

const modelsTableHeadCells = [
    {
        id: 'name',
        numeric: false,
        label: <FormattedMessage id="model" />,
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: <FormattedMessage id="status" />,
        align: 'left'
    },
    {
        id: 'view',
        numeric: false,
        label: <FormattedMessage id="view" />,
        align: 'center',
        disableSort: true
    },
    {
        id: 'edit',
        numeric: false,
        label: <FormattedMessage id="edit" />,
        align: 'center',
        disableSort: true
    },
];

let timerId = null;

const ModelsList = ({ cardMode }) => {
    const [results, setResults] = React.useState(null);

    const navigate = useNavigate();
    const intl = useIntl();

    const editCommand = async (id) => {
        try {
            await MODEL.showModelEditDialog(id, (ok) => {
                if (ok) MODEL_LIST.getModels();
            });                 
        } catch (e) {
            LOGGER.error('Error loading model', e);
            APP.showErrorMessage(<FormattedMessage id="error.model.retrieve" />);
        }
    }

    const refresh = () => {
        timerId = setTimeout(() => {
            try {
                setModelFilter();
                MODEL_LIST.getModels(false); // Don't show status
            } finally {
                refresh();
            }            
        }, 10 * 1000)
    }

    useEffect(() => {
        refresh();
        return () => { clearTimeout(timerId) }
    }, [])

    MODEL_LIST._setResults = setResults;

    const setModelFilter = () => {
        MODEL_LIST.setFilter(`training_update > ${Date.now() - (10 * 60 * 1000)}`); // Last 10 minutes
    };

    const updateResultsCallback = async (page, rowsPerPage, orderBy, orderAscending) => {
        try {
            setTimeout(async () => {
                MODEL_LIST.setPageAndSort(page, rowsPerPage, orderBy, orderAscending);
                setModelFilter();        
                await MODEL_LIST.getModels();
            }, results === null ? 500 : 0);
        } catch (e) {
            LOGGER.error('Error retrieving models', e);
            APP.showErrorMessage(<FormattedMessage id="error.models.retrieving" />);
        }
    }

    return (
        results === null ?
            <TableSkeleton
                tableName={TABLE_NAME}
                cardMode={false}
                headCells={modelsTableHeadCells}
                updateResultsCallback={updateResultsCallback}
                renderRow={() => {
                    return (
                        <>
                            <TableCell>
                                <LabeledImageSkeleton hasSubTitle={false}/>
                            </TableCell>
                            <TableCell>
                                <TextSkeleton sx={{ width: 100}} />
                            </TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                <TableIconButtonSkeleton />
                            </TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                <TableIconButtonSkeleton />
                            </TableCell>
                        </>                    
                    )
                }}
                rowCount = {5}
            /> :    
            <>
                <CommonTable
                    tableName={TABLE_NAME}
                    disableSelection={true}
                    cardView={false}
                    headCells={modelsTableHeadCells}
                    updateResultsCallback={updateResultsCallback}
                    results={results}
                    renderToolbarItems={null}
                    renderRow={(row, index) => {
                        return (
                            <>
                                <TableCell>
                                    <CommonLabeledImage 
                                        title={row.Name} 
                                        description={row.Description} 
                                        imageUrl={row.ImageURL} 
                                        defaultImageUrl={getDefaultModelUrl()} 
                                        rightComponent={MODEL.isPrivate(row) && <PrivateObjectIcon/>}
                                    />
                                </TableCell>
                                <TableCell>                                
                                    {row.Status === ViewModel.StatusPreTraining && 
                                        <CommonLabeledContent
                                            content={<CircularProgress style={{color: '#ffe57f'}} size="20px"/> }
                                            title={intl.formatMessage({ id: 'preTraining' })}
                                        />
                                    }
                                    {row.Status === ViewModel.StatusErrored && 
                                        <CommonLabeledContent
                                            content={<ErrorOutlineOutlinedIcon style={{color: '#f44336'}} size="20px"/> }
                                            title={intl.formatMessage({ id: 'error' })}
                                        />
                                    }
                                    {row.Status === ViewModel.StatusCompleted && 
                                        <CommonLabeledContent
                                            content={<CheckCircleOutlinedIcon style={{color: '#00e676'}} size="20px"/> }
                                            title={intl.formatMessage({ id: 'succeeded' })}
                                        />
                                    }
                                    {row.Status === ViewModel.StatusTraining &&                                     
                                        <CommonLabeledContent
                                            content={<LinearProgress sx={{width: '70px', height: '8px', borderRadius: '10px'}} variant="determinate" 
                                                value={row.Steps === row.TargetSteps ? 100 : ((row.Steps/row.TargetSteps)*100).toFixed(2) | 0}/>}
                                            title={row.Steps === row.TargetSteps ? "100%" : ((row.Steps/row.TargetSteps)*100).toFixed(2) + "%"}
                                        />
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <CommonTableViewColumn onClick={() => {
                                        navigate(`/view-model/${row.ID}`)
                                    }} />
                                </TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    <CommonTableEditColumn onClick={() => editCommand(row.ID) } />
                                </TableCell>
                            </>
                        )
                    }}
                />
            </>
    );
}

const TrainingPage = () => {
    const intl = useIntl();
    return (
        <CommonPageHeader
            createText={intl.formatMessage({ id: 'createModelDots' })}
            title={intl.formatMessage({ id: 'training' })}
        >
            <ModelsList
                key={"modelListMode"}
                cardMode={false} />
        </CommonPageHeader>
    );
};

export default TrainingPage;


