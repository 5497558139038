
import { LOGGER } from '_machina/util/Logging';
import BaseModel from './BaseModel';
import USER_SERVICE from '_machina/service/UserService';

import { openUserEditDialog } from '../dialogs/user-edit-dialog';
import CURRENT_USER from './CurrentUser';

class User extends BaseModel {
  // /**
  //  * @constructor
  //  */
  // constructor() {
  //   super();
  // }
  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  async showUserEditDialog(id, callback) {
    this.showStatus();
    try {
      const result = await USER_SERVICE.get( id );            
      openUserEditDialog(result, {cb: callback});
    } finally {
      this.hideStatus();
    }
  }  

  async createUser(user) {
    this.showStatus();
    try {
        await USER_SERVICE.create(user);
    } finally {
      this.hideStatus();
    }
  }

  async updateUser(user) {
    this.showStatus();
    try {
        await USER_SERVICE.update(user);

        if (user.UserID === CURRENT_USER.getUid()) {
          try {
            await CURRENT_USER.updateCurrentUser();
          } catch (e) {
            LOGGER.error("Error updating current user", e);
          }
        }

    } finally {
      this.hideStatus();
    }
  }

  _reset() {
  }  
}

// Singleton
const USER = new User();

export default USER;
