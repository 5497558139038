import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import DatasetsPage from '_machina/react/pages/datasets-page';
import ModelsPage from '_machina/react/pages/models-page';
import TrainingPage from '_machina/react/pages/training-page';
import UsersPage from '_machina/react/pages/users-page';
import ViewModelPage from '_machina/react/pages/view-model-page';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const SamplePage2 = Loadable(lazy(() => import('views/sample-page-2')));
const Dashboard = Loadable(lazy(() => import('views/dashboard')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/sample-page-2',
            element: <SamplePage2 />
        },
        {
            path: '/datasets',
            element: <DatasetsPage />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/models',
            element: <ModelsPage />
        },
        {
            path: '/training',
            element: <TrainingPage />
        },
        {
            path: '/users',
            element: <UsersPage />
        },
        {
            path: '/view-model/:id',
            element: <ViewModelPage />,
        }
    ]
};

export default MainRoutes;
