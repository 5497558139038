import { Card, CardContent, Grid } from '@mui/material';
import RectangularSkeleton from '_machina/react/components/common/skeleton/RectangularSkeleton';

const SankeySkeleton = () => (
    <Card>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <RectangularSkeleton height={215} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default SankeySkeleton;
