import * as React from 'react';
import { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import PREFS from '_machina/Prefs';

import {
    Divider,
    MenuItem,
    TableCell,
} from '@mui/material';

import { openConfirmDialog } from '_machina/react/components/common/dialog/ConfirmDialog';
import { openModelWizard } from '_machina/react/dialogs/model-wizard-dialog';
import { getDefaultDatasetUrl } from '_machina/assets/images';
import { openDatasetWizard } from '_machina/react/dialogs/dataset-wizard-dialog';
import { LOGGER } from '_machina/util/Logging';
import APP from '_machina/react/model/App';
import DATASET from '_machina/react/model/Dataset';
import DATASET_LIST from '_machina/react/model/DatasetList';
import CommonCardView from '_machina/react/components/common/CommonCardView';
import CommonLabeledImage from '_machina/react/components/common/CommonLabeledImage';
import CommonMenu from '_machina/react/components/common/CommonMenu';
import CommonPageHeader from '_machina/react/components/common/CommonPageHeader';
import CommonTable from '_machina/react/components/common/table/CommonTable';
import CommonTableEditColumn from '_machina/react/components/common/table/CommonTableEditColumn';
import DeleteButton from '_machina/react/components/common/toolbar/DeleteButton';
import TableSkeleton from '_machina/react/components/common/skeleton/TableSkeleton';
import LabeledImageSkeleton from '_machina/react/components/common/skeleton/LabeledImageSkeleton';
import IconButtonSkeleton from '_machina/react/components/common/skeleton/IconButtonSkeleton';
import TableIconButtonSkeleton from '_machina/react/components/common/skeleton/TableIconButtonSkeleton';
import ImageCardSkeleton from '_machina/react/components/common/skeleton/ImageCardSkeleton';
import CommonTableCreateModelColumn from '_machina/react/components/common/table/CommonTableCreateModelColumn';
import PrivateObjectIcon from '_machina/react/components/common/icons/PrivateObjectIcon';

const TABLE_NAME = "datasets-table";

const datasetsTableHeadCells = [
    {
        id: 'name',
        numeric: false,
        label: <FormattedMessage id="dataset" />,
        align: 'left'
    },
    // {
    //     id: 'view',
    //     numeric: false,
    //     label: <FormattedMessage id="view" />,
    //     align: 'center',
    //     disableSort: true
    // },
    {
        id: 'edit',
        numeric: false,
        label: <FormattedMessage id="edit" />,
        align: 'center',
        disableSort: true
    },
    {
        id: 'createmodel',
        numeric: false,
        label: <FormattedMessage id="createModel" />,
        align: 'center',
        disableSort: true
    },
];

const DatasetsList = ({ cardMode }) => {
    const [results, setResults] = React.useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuTargetId, setMenuTargetId] = useState(null);
    const [menuTargetDataset, setMenuTargetDataset] = useState(null);

    const intl = useIntl();

    const handleMenuClick = (id, event, dataset) => {
        setMenuAnchorEl(event.currentTarget);
        setMenuTargetId(id);
        setMenuTargetDataset(dataset);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
    }

    const deleteCommand = async (selected) => {    
        openConfirmDialog(
            <FormattedMessage id={selected.length > 1 ? "delete.datasets.title" : "delete.dataset.title"} />,
            <FormattedMessage id={selected.length > 1 ? "delete.datasets.message" : "delete.dataset.message"} />,
            async () => {
                try {
                    await DATASET_LIST.deleteDatasets(selected)
                } catch (e) {
                    LOGGER.error('Error deleting datasets', e);
                    APP.showErrorMessage(<FormattedMessage id="error.datasets.deleting" />);
                }        
            }
        );
    }

    const editCommand = async (id) => {
        try {
            await DATASET.showDatasetEditDialog(id, (ok) => {
                if (ok) DATASET_LIST.getDatasets();
            });        
        } catch (e) {
            LOGGER.error('Error loading dataset', e);
            APP.showErrorMessage(<FormattedMessage id="error.dataset.retrieve" />);
        }
    }

    DATASET_LIST._setResults = setResults;

    const updateResultsCallback = async (page, rowsPerPage, orderBy, orderAscending) => {
        try {
            setTimeout(async () => {
                DATASET_LIST.setPageAndSort(page, rowsPerPage, orderBy, orderAscending);
                await DATASET_LIST.getDatasets();
            }, results === null ? 500 : 0);
        } catch (e) {
            LOGGER.error('Error retrieving datasets', e);
            APP.showErrorMessage(<FormattedMessage id="error.datasets.retrieving" />);
        }
    }

    return (
        results === null ?
        <TableSkeleton
            tableName={TABLE_NAME}
            cardMode={cardMode}
            headCells={datasetsTableHeadCells}
            updateResultsCallback={updateResultsCallback}
            renderToolbarItems={() => {
                return (
                    <IconButtonSkeleton />
                )
            }}
            renderRow={() => {
                return (
                    <>
                        <TableCell>
                            <LabeledImageSkeleton hasSubTitle={false}/>
                        </TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            <TableIconButtonSkeleton />
                        </TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            <TableIconButtonSkeleton />
                        </TableCell>
                    </>                    
                )
            }}
            renderCard={() => {
                return <ImageCardSkeleton />
            }}
            rowCount = {cardMode ? 6 : 5}
        /> :
        <>
            <CommonTable
                tableName={TABLE_NAME}
                cardView={cardMode}
                headCells={datasetsTableHeadCells}
                updateResultsCallback={updateResultsCallback}
                results={results}
                renderToolbarItems={(selected) => {
                    return (
                        <>
                            <DeleteButton
                                disabled={!selected.length}
                                onClick={() => { deleteCommand(selected) }}
                            />
                        </>
                    )
                }}
                renderRow={(row, index) => {
                    return (
                        <>
                            <TableCell>
                                <CommonLabeledImage 
                                    title={row.Name} 
                                    description={row.Description} 
                                    imageUrl={row.ImageURL} 
                                    defaultImageUrl={getDefaultDatasetUrl()} 
                                    rightComponent={DATASET.isPrivate(row) && <PrivateObjectIcon/>}                                
                                />
                            </TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                <CommonTableEditColumn onClick={() => editCommand(row.ID) } />
                            </TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                <CommonTableCreateModelColumn onClick={() => openModelWizard(row) } />
                            </TableCell>
                        </>
                    )
                }}
                renderCard={(row, index) => {
                    return (
                        <CommonCardView
                            title={row.Name}
                            description={row.Description}
                            imageUrl={row.ImageURL}
                            defaultImageUrl={getDefaultDatasetUrl()}
                            upperRightComponent={DATASET.isPrivate(row) && <PrivateObjectIcon forCard={true}/>}
                            handleMenuClick={(e) => { handleMenuClick(row.ID, e, row) }}
                        />
                    );
                }}
            />
            {menuAnchorEl && (
                <CommonMenu
                    anchorEl={menuAnchorEl}
                    onClose={closeMenu}
                >
                    <MenuItem 
                        onClick={() => {
                            closeMenu();
                            editCommand(menuTargetId);                             
                        }}
                    >
                        {intl.formatMessage({ id: 'edit' })}
                    </MenuItem>
                    <MenuItem onClick={() => {
                            closeMenu();
                            deleteCommand([menuTargetId])                            
                        }}
                    >
                        {intl.formatMessage({ id: 'delete' })}
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={() => {
                            closeMenu();
                            openModelWizard(menuTargetDataset);
                        }}
                    >
                        {intl.formatMessage({ id: 'createModelDots' })}
                    </MenuItem>
                </CommonMenu>
            )}
        </>
    );
}

const DatasetsPage = () => {
    const [cardMode, setCardMode] = React.useState(PREFS.getBoolPreference(TABLE_NAME + ".cardMode", true));

    const intl = useIntl();
    return (
        <CommonPageHeader
            createText={intl.formatMessage({ id: 'createDatasetDots' })}
            onCreate={() => { openDatasetWizard() }}
            cardMode={cardMode}
            setCardMode={(mode) => {
                setCardMode(mode);
                PREFS.setPreference(TABLE_NAME + ".cardMode", mode)
            }}
            title={intl.formatMessage({ id: 'datasets' })}
            search={true}
            searchPlaceholder={intl.formatMessage({ id: 'searchDatasets' })}>
            {cardMode ? (
                <DatasetsList
                    key={"datasetCardMode"}
                    cardMode={true} />
            ) : (
                <DatasetsList
                    key={"datasetListMode"}
                    cardMode={false} />
            )}
        </CommonPageHeader>
    );
};

export default DatasetsPage;


