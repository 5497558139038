import React from 'react';

import { useRef } from 'react';
import { useTheme } from '@mui/system';
import { FormattedMessage } from 'react-intl';

import { Box, Button, CardActions, Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { ChevronRightOutlined, ChevronLeftOutlined } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';

import PerfectScrollbar from 'react-perfect-scrollbar';

import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

import { usePrevious } from '_machina/react/Util';
import colorAssigner from '_machina/util/ColorAssigner';
import VIEW_MODEL from '_machina/react/model/ViewModel';
import EditFeatureButton from './EditFeatureButton';
import CommonTooltip from '_machina/react/components/common/CommonTooltip';

const FeaturesComponent = () => {
  const [viewAll, setViewAll] = React.useState(false);
  const [counter, setCounter] = React.useState(-3);
  const ps = useRef()

  const theme = useTheme();
  const title = <FormattedMessage id="features" />;

  const features = VIEW_MODEL.getFeatures();
  const subModel = VIEW_MODEL.getSelectedSubModelId()
  const oldSubModel = usePrevious(subModel);

  const isPredict = VIEW_MODEL.isPredictMode();

  function scrollTop() {
    const curr = ps.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  }

  if (oldSubModel !== subModel) {
    setTimeout(() => {
      setCounter(0);
      scrollTop();
    }, 100);
    return;
  } else {
    if (counter < features.length) {
      const ptSubModel = subModel;
      setTimeout(() => {
        if (VIEW_MODEL.getSelectedSubModelId() !== ptSubModel) return;
        setCounter(counter + 1)
      }, 100);
    }
  }

  return (
    <MainCard title={title} content={false}>
      <PerfectScrollbar style={{ height: viewAll ? undefined : 450 }} containerRef={el => (ps.current = el)}>
        <List component="nav" sx={{ paddingTop: 0, paddingBottom: 0, overflowX: 'hidden' }}>
          {features.map((feature, index) => {
            let color = colorAssigner(feature.importance);
            const valueObj = VIEW_MODEL.getValue(feature.name);
            const value = valueObj ? valueObj.value : null;
            const isDefault = valueObj.isDefault;
            if (feature.importance === 0) color = '#888';
            return (
              <div key={subModel + "-" + index + "-" + feature.name + "-" + value + "-" + isPredict}>
                {index > 0 && (
                  <Divider />
                )}
                <Transitions type={isDefault ? "slide" : "fade"} in={counter > 0} direction={"left"}>
                  <CommonTooltip title={VIEW_MODEL.getOverrides().getColumnDescription(feature.name)} placement="left" arrow>
                    <ListItemButton
                      sx={{ py: .5, px: 2 }}
                      style={{ ...(!isPredict ? { backgroundColor: 'transparent', cursor: 'default' } : { cursor: 'pointer' }) }}
                      disableRipple={!isPredict}
                      onClick={isPredict ? (e) => {
                        e.stopPropagation();
                        VIEW_MODEL.openFeatureEditor(feature.name);
                      } : null}
                    >
                      <ListItemIcon sx={{ mr: -.75 }}>
                        <CircleIcon sx={{ width: '16px', color: color, opacity: feature.importance === 0 ? .3 : 1 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Box sx={{ overflow: 'hidden' }}>
                              <Box sx={{ mb: .5 }}>
                                <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                  {VIEW_MODEL.getOverrides().getColumnAlias(feature.name)} {isDefault ? "" : "*"}
                                </Typography>
                              </Box>
                              <Box sx={{ overflow: 'hidden' }}>
                                <Typography variant="subtitle2" sx={{
                                  fontWeight: 800,
                                  fontSize: '100%',
                                  color: isDefault ? 'inherit' : theme.palette.primary.main,
                                  textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
                                }}>
                                  {VIEW_MODEL.getOverrides().getColumnValueAlias(feature.name, value)}
                                </Typography>
                              </Box>
                            </Box>
                            <EditFeatureButton
                              isTarget={false}
                              featureName={feature.name}
                            />
                          </Stack>
                        }
                      />
                    </ListItemButton>
                  </CommonTooltip>
                </Transitions>
              </div>
            );
          })}
        </List>
      </PerfectScrollbar>
      <Divider />
      <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
        <Button
          size="small"
          disableElevation
          onClick={() => { setViewAll(!viewAll); }}
        >
          {viewAll ? (
            <>
              <ChevronLeftOutlined sx={{ height: '20px' }} />
              <FormattedMessage id="collapse" />
            </>
          ) : (
            <>
              <FormattedMessage id="viewAll" />
              <ChevronRightOutlined sx={{ height: '20px' }} />
            </>
          )}
        </Button>
      </CardActions>

      {/* <Box sx={{p: 3}}>
        Show all...
      </Box> */}
    </MainCard>
  );
};

export default FeaturesComponent;
