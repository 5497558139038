import React from 'react';

// material-ui
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LOGGER } from '_machina/util/Logging';

import { uuidv4 } from '_machina/util/Uuid';
import { CommonDialog, CommonDialogTabPanel } from '_machina/react/components/common/dialog/CommonDialog';
import APP from '_machina/react/model/App';
import USER from '_machina/react/model/User';
import CommonTextField from '_machina/react/components/common/fields/CommonTextField';
import CommonImageChooser from '_machina/react/components/common/fields/CommonImageChooser';
import Validator from '_machina/react/components/common/validation/Validator';
import { useForceUpdate } from '_machina/react/Util';

let _setUniqueId = null;
let _setCallback = null;
let _setUser = null;
let _chooserState = null;

const setChooserState = (state) => {
    _chooserState = state;
}

const getChooserState = () => {
    return _chooserState;
}

const validator = new Validator();

const GENERAL_TAB = 0;
const AVATAR_TAB = 1;

export function openUserEditDialog(user, callback) {
    _chooserState = null;
    validator.reset();
    _setUniqueId(uuidv4());
    _setCallback(callback);
    _setUser(user);
}

export default function UserEditDialog() {
  const [callback, setCallback] = React.useState(null);
  const [uniqueId, setUniqueId] = React.useState(null);
  const [user, setUser] = React.useState(null);

  _setUser = setUser;
  _setCallback = setCallback;
  _setUniqueId = setUniqueId;

  return (
    <UserEditDialogInner
      key={uniqueId}
      callback={async (ok) => {
        if (!ok) {
          if (callback?.cb) callback.cb(false)
          setUser(null); // close dialog
        } else {
          let succeeded = false;
          try {
            await USER.updateUser(user);
            succeeded = true;
          } catch (e) {
            LOGGER.error('Error saving user', e);
            APP.showErrorMessage(<FormattedMessage id="error.users.saving" />);
          }
          if (succeeded) {
            if (callback?.cb) callback.cb(true)
            setUser(null); // close dialog
          }
        }
      }}
      user={user}
      setUser={setUser}
    />
  )
}

function UserEditDialogInner({ callback, user, setUser }) {
  const [userImage, setUserImage] = React.useState(null);
  const [tab, setTab] = React.useState(GENERAL_TAB);
  const forceUpdate = useForceUpdate();

  const currentUserImage = userImage || (user ? user.ImageURL : null);
  const open = user !== null;

  if (!open) return;

  const validateCallback = (ok) => {
    if (ok) {
      validator.checkMinLength(GENERAL_TAB, "name", user.Name);
      // validator.checkValidEmail(GENERAL_TAB, "email", user.Email);
      const minTab = validator.getMinInvalidTab();
      if (minTab >= 0) {
        setTab(minTab);
        forceUpdate();
        return false;
      }      
    } 
    callback(ok);
  }

  return (
    <CommonDialog
      title={<FormattedMessage id="editUser" />}
      open={open}
      callback={validateCallback}
      tab={tab}
      setTab={setTab}
      tabs={[{
        value: GENERAL_TAB,
        label: <FormattedMessage id="general" />
      },/* {
        value: "password",
        label: <FormattedMessage id="password" />
      },*/ {
        value: AVATAR_TAB,
        label: <FormattedMessage id="avatar" />
      }]}
      tabPanels={
        open && (
          <>
            <CommonDialogTabPanel tab={tab} value={GENERAL_TAB}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CommonTextField
                    label={<FormattedMessage id="name" />}
                    value={user.Name}
                    onChange={(e) => {
                      setUser({ ...user, Name: e.target.value })
                    }}
                    required
                    helperText={
                      !validator.isValid(GENERAL_TAB, "name")
                        ? <FormattedMessage id="validation.name" /> : null
                    }
                    error={!validator.isValid(GENERAL_TAB, "name")}                                        
                  />
                </Grid>
                <Grid item xs={12}>
                  <CommonTextField
                    disabled
                    label={<FormattedMessage id="email" />}
                    value={user.Email}
                    // onChange={(e) => {
                    //   setUser({ ...user, Email: e.target.value })
                    // }}
                  />
                </Grid>
              </Grid>
            </CommonDialogTabPanel>
            {/* <CommonDialogTabPanel tab={tab} value={"password"}>
            </CommonDialogTabPanel> */}
            <CommonDialogTabPanel tab={tab} value={AVATAR_TAB}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CommonImageChooser
                    avatarMode={true}
                    currentImage={currentUserImage}
                    onSetImage={(image) => {
                      setUserImage(image)
                      setUser({ ...user, ImageURL: image })
                    }}
                    setChooserState={setChooserState}
                    getChooserState={getChooserState}
                  />
                </Grid>
              </Grid>
            </CommonDialogTabPanel>
          </>
        )}
    />
  );
}
