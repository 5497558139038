import React from 'react';

import { Grid } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import Chart from 'react-apexcharts';

import MainCard from 'ui-component/cards/MainCard';

import VIEW_MODEL from '_machina/react/model/ViewModel';

function getChartData(intl) {
  return {
    height: 250,
    type: 'donut',
    options: {
      colors: ['rgb(124, 77, 255)', 'rgb(33, 150, 243)', 'rgb(244, 67, 54)'],
      stroke: {
        colors: ['rgb(33, 41, 70)']
      },
      dataLabels: {
        enabled: false
      },
      labels: [
        intl.formatMessage({ id: 'high' }),
        intl.formatMessage({ id: 'medium' }),
        intl.formatMessage({ id: 'low' })
      ],
      legend: {
        show: true,
        position: 'bottom',
        fontFamily: 'inherit',
        labels: {
          colors: 'inherit'
        },
        itemMargin: {
          horizontal: 10,
          vertical: 10
        }
      },
    },
  };
}

const FeatureImportancePieChart = ({ subModelId, isPredict }) => {
  const [count, setCount] = React.useState(null);
  const [series, setSeries] = React.useState([]);

  const intl = useIntl();

  React.useEffect(() => {
    const features = VIEW_MODEL.getFeatures();
    let low = 0;
    let med = 0;
    let high = 0;
    for (let i = 0; i < features.length; i++) {
      const f = features[i];
      const importance = f.importance;
      if (importance <= 0.33) {
        low++;
      } else if (importance > 0.33 && importance <= 0.66) {
        med++;
      } else {
        high++;
      }
    }
    setSeries([high, med, low])
    setCount(count + 1);
  }, [subModelId, isPredict]);

  return (
    <MainCard title={<FormattedMessage id="featureImportance" />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Chart
            key={count}
            series={series}
            {...getChartData(intl)}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default FeatureImportancePieChart;
