import { FC } from 'react';
import { Menu } from 'reablocks';
import classNames from 'classnames';
import css from './SankeyMenu.module.css';

export interface SankeyMenuOption {
  label: string;
  value: string;
}

export interface SankeyMenuProps {
  reference: any;
  open?: boolean;
  selection?: string;
  options?: SankeyMenuOption[];
  onMenuClose?: () => void;
  onMenuChange?: (option: SankeyMenuOption) => void;
}

export const SankeyMenu: FC<SankeyMenuProps> = ({
  open,
  selection,
  reference,
  options,
  onMenuClose,
  onMenuChange
}) => (
  <Menu
    reference={reference}
    open={open}
    placement="bottom"
    onClose={onMenuClose}
  >
    <div role="list" className={css.menu}>
      {options?.map((option, i) => (
        <button
          key={option.value}
          className={classNames(css.menuBtn, { [css.active]: selection === option.value })}
          role="listitem"
          onClick={() => {
            onMenuClose?.();
            onMenuChange?.(option);
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  </Menu>
);
