import * as React from 'react';

import { Box } from '@mui/material';
import RectangularSkeleton from './RectangularSkeleton';
import TextSkeleton from './TextSkeleton';

export default function LabeledImageSkeleton({ hasSubTitle, children }) {
  const body = (
    <>
      <TextSkeleton sx={{ width: 120}} />
      {hasSubTitle &&
        <TextSkeleton sx={{ height: 16, width: 100}} />
      }
    </>
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <RectangularSkeleton sx={{ width: 40, height: 40, m: hasSubTitle ? .4 : .25 }} />
      </Box>
      <Box sx={{ marginLeft: '16px' }}>
        {body}
      </Box>
    </Box >
  );
}