import CONFIG from '../config/Config';

/**
 * Base service implementation
 */
export default class BaseService {
  /**
   * Returns the headers to use for a request
   * @param {string} token The token
   * @param {string} contentType The content type
   * @returns The headers to use for a request
   */
  _getHeaders(token, contentType = 'application/json') {
    const headers = {};
    if (token) {
      headers['Authorization'] = `bearer ${token}`;
    }

    if (contentType) {
      headers['content-type'] = contentType;
    }
    return headers;
  }

  /**
   * Checks the response from a request.
   * Will raise errors, etc. based on the response information.
   * @async
   * @param {object} res The response
   */
  async _checkResponse(res) {
    if (!res.ok) {
      let message = `${res.status}`;
      let resText = null;
      try {
        resText = await res.text();
      } catch (e) {
        /**/
      }
      if (res.statusText && res.statusText.length > 0) {
        message = `${message} : ${res.statusText}`;
      }
      if (resText && resText.length > 0) {
        message = `${message} : ${resText}`;
      }

      throw new Error(message);
    }
  }

  /**
   * Performs a get request
   * @async
   * @param {string} token The token
   * @param {string} path The path for the request
   * @returns
   */
  async _fetchGet(token, path) {
    const url = `${CONFIG.getBaseUrl()}${path}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: this._getHeaders(token),
    });
    await this._checkResponse(res);
    return await res.json();
  }

  /**
   * Performs a delete request
   * @async
   * @param {string} token The token
   * @param {string} path The path for the request
   * @returns
   */
  async _fetchDelete(token, path) {
    const url = `${CONFIG.getBaseUrl()}${path}`;
    const res = await fetch(url, {
      method: 'DELETE',
      headers: this._getHeaders(token),
    });
    await this._checkResponse(res);
    return await res.json();
  }

  /**
   * Performs a post request
   * @async
   * @param {string} token The token
   * @param {string} path The path for the request
   * @param {string} body The body for the request
   * @param {boolean} stringify Whether to stringify the body (JSON)
   * @param {string} contentType The content type
   * @param {boolean} isCompleteUrl Whether the path specified is the full URL
   * @returns
   */
  async _fetchPost(
    token,
    path,
    body,
    stringify = true,
    contentType = 'application/json',
    isCompleteUrl = false,
    rawResponse = false
  ) {
    const url = isCompleteUrl ? path : `${CONFIG.getBaseUrl()}${path}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: this._getHeaders(token, contentType),
      body: stringify ? JSON.stringify(body) : body,
    });
    await this._checkResponse(res);
    return !rawResponse ? await res.json() : res;
  }

  /**
   * Performs a post request
   * @async
   * @param {string} token The token
   * @param {string} path The path for the request
   * @param {string} body The body for the request
   * @param {boolean} stringify Whether to stringify the body (JSON)
   * @param {string} contentType The content type
   * @param {boolean} isCompleteUrl Whether the path specified is the full URL
   * @returns
   */
  async _fetchPut(
    token,
    path,
    body,
    stringify = true,
    contentType = 'application/json',
    isCompleteUrl = false,
  ) {
    const url = isCompleteUrl ? path : `${CONFIG.getBaseUrl()}${path}`;
    const res = await fetch(url, {
      method: 'PUT',
      headers: this._getHeaders(token, contentType),
      body: stringify ? JSON.stringify(body) : body,
    });
    await this._checkResponse(res);
    return await res.json();
  }
}
