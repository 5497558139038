import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Box, CircularProgress, IconButton, Grid, Avatar, LinearProgress, Typography } from '@mui/material';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Transitions from 'ui-component/extended/Transitions';

import { LOGGER } from '_machina/util/Logging';
import { uuidv4 } from '_machina/util/Uuid';
import Validator from '../../validation/Validator';
import { useForceUpdate } from '_machina/react/Util';

import APP from '_machina/react/model/App';
import TEXT_TO_IMAGE_SERVICE from '_machina/service/TextToImageService';

import CommonButton from '../CommonButton';
import CommonTextField from '../CommonTextField';
import { CommonDialog, CommonDialogTabPanel } from '../../dialog/CommonDialog';
import { ValidationError } from 'yup';

let _setUniqueId = null;
let _setOpen = null;
let _setState = null;
let _model = null;
let _onImageUrlSelected = null;
let _setDialogState = null;
let _avatarMode = false;

const validator = new Validator();

class Model {
  constructor(state, defaultImageUrl) {
    if (!state) {
      state = {}
      state.images = [];
      state.images.push(defaultImageUrl);
      state.currentImageIndex = 0;
      state.progress = null;
      state.prompt = "";
      state.negativePrompt = "";
      state.generating = false;
    }
    this.state = state;
    this._resetGenerating(false);
    this._updateState(this.state);
    this.timeoutId = null;
  }

  setPrompt(prompt) {
    this.state.prompt = prompt;
    this._updateState(this.state);
  }

  getPrompt() {
    return this.state.prompt;
  }

  setNegativePrompt(prompt) {
    this.state.negativePrompt = prompt;
    this._updateState(this.state);
  }

  getNegativePrompt() {
    return this.state.negativePrompt;
  }

  getCurrentImageIndex() {
    return this.state.currentImageIndex;
  }

  _onError(e) {
    LOGGER.error('Error generating ', e);
    APP.showErrorMessage(<FormattedMessage id="error.image.generation" />);
    // Reset generating
    this._resetGenerating();
  }

  _resetGenerating(update = true) {
    // Reset generating
    this.state.generating = false;
    this.state.progress = null;
    if (update) {
      this._updateState(this.state);
    }
  }

  _updateProgress(result) {
    LOGGER.info(result);

    let progress = this.state.progress;
    if (!progress) {
      progress = {}
      progress.steps = result.queue_position;
      progress.step = 0;
      progress.postStep = 0;
    } else {
      progress.step = progress.steps - result.queue_position;
    }
    progress.percent = (progress.step / progress.steps) * 100;
    progress.position = progress.steps - progress.step;
    this.state.progress = progress;
    this._updateState(this.state);

    LOGGER.info(this.state.progress);

    if (!result.finished) {
      this.timeoutId = setTimeout(async () => {
        try {
          const res = await TEXT_TO_IMAGE_SERVICE.status(result.uuid);
          this._updateProgress(res);
          if (progress.position === 0) {
            progress.postStep++;
          }
        } catch (e) {
          this._onError(e);
        }
      }, 5000);
    } else {
      this.timeoutId = null;
      const retrieve = async () => {
        try {
          const imageUrl = await TEXT_TO_IMAGE_SERVICE.retrieve(result.uuid);
          this.state.images.push(imageUrl);
          this.state.currentImageIndex = this.state.images.length - 1;
          this._resetGenerating(false);
          this._updateState(this.state);
        } catch (e) {
          this._onError(e);
        }
      }
      retrieve();
    }
  }

  getState() {
    return this.state;
  }

  destroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  async submit() {
    this.state.generating = true;
    this._updateState(this.state);

    try {
      const result = await TEXT_TO_IMAGE_SERVICE.submit({
        prompt: this.getPrompt(),
        negativePrompt: this.getNegativePrompt()
      })
      this._updateProgress(result);
    } catch (e) {
      this._onError(e);
    }
  }

  isGenerating() {
    return this.state.generating;
  }

  getProgress() {
    return this.state.progress;
  }

  getCurrentImageUrl() {
    const state = this.state;
    return state.images[state.currentImageIndex];
  }

  nextImage() {
    if (this.isNextImageAvailable()) {
      this.state.currentImageIndex++;
      this._updateState(this.state);
    }
  }

  isNextImageAvailable() {
    const state = this.state;
    return state.currentImageIndex < (state.images.length - 1);
  }

  previousImage() {
    if (this.isPreviousImageAvailable()) {
      this.state.currentImageIndex--;
      this._updateState(this.state);
    }
  }

  isPreviousImageAvailable() {
    const state = this.state;
    return state.currentImageIndex > 0;
  }

  _updateState(state) {
    this.state = state;
    _setState({ ...state })
  }
}

export function openTextToImageDialog(avatarMode, getDialogState, setDialogState, defaultImageUrl, onImageUrlSelected) {
  validator.reset();
  _avatarMode = avatarMode;
  _model = new Model(getDialogState ? getDialogState() : null, defaultImageUrl);
  _onImageUrlSelected = onImageUrlSelected;
  _setDialogState = setDialogState;
  _setUniqueId(uuidv4());
  _setOpen(true);
}

export default function TextToImageDialog() {
  const [uniqueId, setUniqueId] = React.useState(null);
  const [_, setState] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  _setUniqueId = setUniqueId;
  _setOpen = setOpen;
  _setState = setState;

  if (!open) return;

  return (
    <TextToImageDialogInner
      key={uniqueId}
      open={open}
      setOpen={setOpen}
    />
  )
}

function TextToImageDialogInner({ open, setOpen }) {

  const avatarMode = _avatarMode;

  const forceUpdate = useForceUpdate();

  const props = {}
  if (!avatarMode) props.variant = 'rounded';
  const content = !avatarMode ? "" : null;
  const widthOut = /*width ? width :*/ avatarMode ? 160 : 220;
  const height = avatarMode ? widthOut : widthOut * .75;
  const sx = {}
  if (!avatarMode) sx.backgroundColor = 'transparent';

  const progress = _model.getProgress();
  const currentImageUrl = _model.getCurrentImageUrl();

  return (
    <CommonDialog
      title={<FormattedMessage id="dialog.imageGeneration.title" />}
      height={325}
      open={open}
      tabs={[{
        value: "one",
        label: "nada",
      }]}
      callback={(ok) => {
        if (ok) {
          _onImageUrlSelected(_model.getCurrentImageUrl());
        }
        if (_setDialogState) _setDialogState(_model.getState());
        _model.destroy();
        setOpen(false);
      }}
      tabPanels={
        open && (
          <>
            <CommonDialogTabPanel tab={"one"} value={"one"}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <FormattedMessage id="dialog.imageGeneration.prompt" />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: .5 }}>
                      <CommonTextField
                        required
                        helperText={
                          !validator.isValid(0, "prompt")
                            ? <FormattedMessage id="validation.prompt" /> : null
                        }
                        error={!validator.isValid(0, "prompt")}
                        label={<FormattedMessage id="prompt" />}
                        value={_model.getPrompt()}
                        onChange={(e) => { _model.setPrompt(e.target.value) }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CommonTextField
                        label={<FormattedMessage id="negativePrompt" />}
                        value={_model.getNegativePrompt()}
                        onChange={(e) => { _model.setNegativePrompt(e.target.value) }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <CommonButton
                        disabled={_model.isGenerating()}
                        onClick={() => { 
                          validator.reset();
                          validator.checkMinLength(0, "prompt", _model.getPrompt());                          
                          if (validator.getMinInvalidTab() >= 0) {
                            forceUpdate();                            
                          } else {
                            _model.submit() 
                          }
                        }}
                      >
                        <FormattedMessage id="generate" />
                      </CommonButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <IconButton
                        disabled={!_model.isPreviousImageAvailable()}
                        onClick={() => { _model.previousImage() }}
                        sx={{
                          mr: 1.5,
                        }}
                      >
                        <ChevronLeftRoundedIcon fontSize="medium" />
                      </IconButton>
                      <Box key={"image" + _model.getCurrentImageIndex()}>
                        <Transitions type="fade" in={true}>
                          <Avatar
                            {...props}
                            src={currentImageUrl}
                            sx={{ width: widthOut, height: height }}>
                            {content}
                          </Avatar>
                        </Transitions>
                      </Box>
                      <IconButton
                        disabled={!_model.isNextImageAvailable()}
                        onClick={() => { _model.nextImage() }}
                        sx={{
                          ml: 1.5,
                        }}>
                        <ChevronRightRoundedIcon fontSize="medium" />
                      </IconButton>
                    </Grid>
                    {progress &&
                      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {progress.postStep < 2 ?
                          <Transitions type="fade" in={true}>
                            <Grid container spacing={2} alignItems="center" justifyContent="center"
                              sx={{ maxWidth: '300px', minWidth: '250px' }}
                            >
                              <Grid item>
                                <Typography variant="caption">
                                  <FormattedMessage id="queue" />
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <LinearProgress
                                  sx={{
                                    height: 10,
                                    borderRadius: 5,
                                  }}
                                  variant="determinate"
                                  color="primary"
                                  value={progress.percent}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">{Math.round(progress.percent)}%</Typography>
                              </Grid>

                            </Grid>
                          </Transitions> :
                          <Box key={"generating-status"}>
                            <Transitions type="fade" in={true}>
                              <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <CircularProgress size={20} />
                                  <Box sx={{ marginLeft: '10px' }}>
                                    <Typography>
                                      <FormattedMessage id="generating" />
                                    </Typography>
                                  </Box>
                                </Box >
                              </Grid>
                            </Transitions>
                          </Box>
                        }
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </CommonDialogTabPanel>
          </>
        )}
    />
  );
}


