import { useRef, useEffect, useState } from 'react';


/**
 * Displays a file chooser
 * @param {string} contentType The content type to select (images, etc.)
 * @param {boolean} multiple Whether to allow multiple files to be selected
 * @returns A promise that will resolve with the selected file(s)
 */
export function selectFile(contentType, multiple) {
    return new Promise((resolve) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = multiple;
        input.accept = contentType;
        input.onchange = (_) => {
            let files = Array.from(input.files);
            if (multiple) resolve(files);
            else resolve(files[0]);
        };
        input.click();
    });
}

/**
 * Returns the base 64 representation of the specified file
 * @param {File} file The file
 * @returns The base 64 representation of the specified file
 */
export function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

/**
 * Hook to force updates to an associated React component
 * @returns The function used to force updates
 */
export function useForceUpdate() {
    const [, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
}
