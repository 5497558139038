import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import CommonButton from '../fields/CommonButton';

export function CommonDialog({ title, height, open, callback, tab, setTab, tabs, tabPanels, ...other }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const defaults = {
        fullWidth: true,
        maxWidth: "md"
    }

    const marginY = tabs && tabs.length > 1 ? 2 : 0;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            scroll="paper"
            {...defaults}
            {...other}
            // fullWidth={true}
            // maxWidth={"md"}
            onClose={(event, reason) => { 
                if (reason && reason == "backdropClick") return;
                callback(false) 
            }}>
            {open && (
                <>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent sx={{ height: height || 400, mb: 0, pb: 0 }}>
                        {tabs && tabs.length > 1 && (
                            <Tabs
                                value={tab}
                                onChange={(e, v) => setTab(v)}
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile>
                                {tabs.map((row, index) => {
                                    return <Tab key={row.value} value={row.value} label={row.label} />
                                })}
                            </Tabs>
                        )}
                        <DialogContent sx={{ my: marginY, px: 2 }}>
                            {tabPanels}
                        </DialogContent>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <CommonButton
                            onClick={() => { callback(false) }}>
                            <FormattedMessage id="cancel"/>
                        </CommonButton>
                        <CommonButton
                            variant="outlined"
                            onClick={() => { callback(true) }}>
                            <FormattedMessage id="ok"/>
                        </CommonButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

export function CommonDialogTabPanel({value, tab, children}) {
  return (
    value === tab ? <>{children}</> : <></>
  );
}
