import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <img alt="Machina" src="machina.svg" style={{width: '140px', marginLeft: '-10px'}}/>
    </Link>
);

export default LogoSection;
