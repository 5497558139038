import BaseService from './BaseService';
import CURRENT_USER from '_machina/react/model/CurrentUser';

/**
 * Service used to access users
 */
class UserService extends BaseService {

  async getList(page = 0, pageSize = 25, sortColumn = null, sortAscending = true) {
    let url = `/api/user/list?page=${page}&pageSize=${pageSize}`;
    if (sortColumn) {
      url = `${url}&sortColumn=${sortColumn}&sortAscending=${sortAscending}`
    }
    // console.log(url)
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async get(userId) {
    const url = `/api/user/${userId}`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async getByUid(uid) {
    const url = `/api/user/get-by-uid/${uid}`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async delete(userId) {
    const url = `/api/user/${userId}`;
    return await this._fetchDelete(await CURRENT_USER.getAuthToken(), url);
  }

  async create(user) {
    const url = `/api/user`;
    return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, user);
  }

  async update(user) {
    const url = `/api/user/${user.ID}`;
    return await this._fetchPut(await CURRENT_USER.getAuthToken(), url, user);
  }
}

// Singleton
const USER_SERVICE = new UserService();

export default USER_SERVICE;
