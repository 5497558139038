import BaseService from './BaseService';
import CURRENT_USER from '_machina/react/model/CurrentUser';

/**
 * Service used to access sub-models
 */
class SubModelService extends BaseService {

  async findByModel(modelId) {
    const url = `/api/submodel/find-by-model/${modelId}`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async getValues(subModelId) {
    const url = `/api/submodel/${subModelId}/values`;
    return await this._fetchGet(await CURRENT_USER.getAuthToken(), url);
  }

  async predict(subModelId, values) {
    const url = `/api/submodel/${subModelId}/predict`;
    return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, {
        "arg_values": values
    });
  }

  async optimal(subModelId, value) {
    const url = `/api/submodel/${subModelId}/optimal`;
    return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, {
        "target_value": value
    });
  }
}

// Singleton
const SUB_MODEL_SERVICE = new SubModelService();

export default SUB_MODEL_SERVICE;
