import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from "react-router-dom";

import { useForceUpdate } from '_machina/react/Util';
import { uuidv4 } from '_machina/util/Uuid';
import { LOGGER } from '_machina/util/Logging';

import APP from '_machina/react/model/App';

import DATASET from '_machina/react/model/Dataset';
import WORKFLOW from '_machina/react/model/Workflow';

import { initAnalysisResults } from '../dataset-wizard-dialog/util';
import { CommonWizard } from '_machina/react/components/common/wizard/CommonWizard';
import Validator from '_machina/react/components/common/validation/Validator';

import FileAnalysisModel from '../dataset-wizard-dialog/FileAnalysisModel';

import { createModelDetailsStep, ModelDetailsStep } from '../dataset-wizard-dialog/steps/ModelDetailsStep';
import { createModelTargetsStep, ModelTargetsStep } from '../dataset-wizard-dialog/steps/ModelTargetsStep';
import { createModelFeaturesStep, ModelFeaturesStep } from '../dataset-wizard-dialog/steps/ModelFeaturesStep';

let _setUniqueId = null;
let _setOpen = null;
let _setInitialState = null;

let _chooserState = null;

const setChooserState = (state) => {
    _chooserState = state;
}

const getChooserState = () => {
    return _chooserState;
}

const validator = new Validator();

export function openModelWizard(dataset) {
    console.log(dataset);
    (async () => {
        try {
            const result = await DATASET.analyzeFile(dataset.StoragePath);
            console.log(result);

            // Initial state
            const state = {
                dataset: {},
                datasetId: dataset.ID,
                model: {
                    Name: dataset.Name,
                    Description: dataset.Description,
                    ImageURL: dataset.ImageURL,
                },
                trainModel: true,
                viewModel: true,
                iterations: 2,        
            }

            // Initialize the results
            initAnalysisResults(state.dataset, result);

            // Attempt to load overrides
            try {
                const overrides = await DATASET.getOverrides(dataset.ID);
                state.datasetOverrides = overrides;
            } catch(e) {
                LOGGER.error("Error attempting to read overrides", e);
            }
            
            _chooserState = null;            
            _setUniqueId(uuidv4());
            _setInitialState(state);
            _setOpen(true);    
        } catch (e) {
            LOGGER.error(e);
            APP.showErrorMessage(<FormattedMessage id="error.dataset.analyzing" />)
        }
    })();
}

export default function ModelWizardDialog() {
    const [uniqueId, setUniqueId] = React.useState(null);
    const [initialState, setInitialState] = React.useState(null);    
    const [open, setOpen] = React.useState(false);

    _setUniqueId = setUniqueId;
    _setInitialState = setInitialState;
    _setOpen = setOpen;

    if (!open) return;

    return (
        <ModelWizardDialogInner
            key={uniqueId}
            open={open}
            setOpen={setOpen}
            initialState={initialState}
        />
    )
}

const modelDetailsStep = createModelDetailsStep(validator, <FormattedMessage id="details" />);
const modelTargetsStep = createModelTargetsStep(validator, <FormattedMessage id="targets" />);
const modelFeaturesStep = createModelFeaturesStep(validator, <FormattedMessage id="features" />);

const STEPS = [
    modelDetailsStep, 
    modelFeaturesStep, 
    modelTargetsStep
]

function ModelWizardDialogInner({ open, setOpen, initialState }) {
    const [step, setStep] = React.useState(0);
    const [state, setState] = React.useState({...initialState});
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();

    console.log(state)

    const onFinish = () => {
        (async () => {
            let succeeded = false;
            let result = null
            try {
                const postState = JSON.parse(JSON.stringify(state));
                const model = new FileAnalysisModel(postState.dataset.AnalysisResults);
                delete postState.dataset.AnalysisResults;
                postState.AnalysisResults = model.cloneForPost().getAnalysis();

                const columnTypes = {};
                const predictionTypes = {};

                for (const [column, value] of Object.entries(postState.AnalysisResults.columns)) {
                    columnTypes[column] = value.type;
                    predictionTypes[column] = value.predict.modelType;
                }
                  
                const toPost = {
                    datasetId: postState.datasetId,
                    model: postState.model,
                    training: {
                        iterations: postState.iterations,
                        excludedColumns: postState.AnalysisResults.excludedColumns,
                        predictionColumns: postState.AnalysisResults.predictionColumns,
                        columnTypes: columnTypes,
                        predictionTypes: predictionTypes    
                    },
                }

                LOGGER.info("## POSTING:");
                LOGGER.info(toPost);

                result = await WORKFLOW.train(toPost)                
                succeeded = true;                
            } catch (e) {
                LOGGER.error('Error creating model', e);
                APP.showErrorMessage(<FormattedMessage id="error.model.create" />);
            }
            if (succeeded) {
                if (state.viewModel && result?.modelId >= 0) {
                    navigate(`/view-model/${result.modelId}`);
                }
                setOpen(false);
            }
        })();
        return false;
    }

    const onCancel = () => {
        return true;
    }

    return (
        <CommonWizard
            title={<FormattedMessage id="createModel" />}
            onCancel={onCancel}
            onFinish={onFinish}
            open={open}
            setOpen={setOpen}
            step={step}
            setStep={setStep}
            steps={STEPS}
            stepsInfo={forceUpdate}
            state={state}
            setState={setState}
            height={540}
            stepPanels={
                open && (
                    <>
                        <ModelDetailsStep
                            modelDetailsStep={modelDetailsStep}
                            step={step}
                            steps={STEPS} 
                            state={state}
                            setState={setState}
                            validator={validator} 
                            setChooserState={setChooserState}
                            getChooserState={getChooserState}
                        />
                        <ModelFeaturesStep
                            modelFeaturesStep={modelFeaturesStep}
                            step={step}
                            steps={STEPS} 
                            state={state}
                            setState={setState}
                            validator={validator} 
                        />
                        <ModelTargetsStep
                            modelTargetsStep={modelTargetsStep}
                            step={step}
                            steps={STEPS} 
                            state={state}
                            setState={setState}
                            validator={validator} 
                        />
                    </>
                )
            }
        />
    );
}
