import { useTheme } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

export default function FileStatus({ fileModel }) {    
    const theme = useTheme();

    const excludeCount = Object.keys(fileModel.getExcludedColumns()).length;

    let color = undefined;
    let message = <FormattedMessage id="datasetValid"/>;

    // TODO: Fix the exclude count issue (flips to valid, then valid with exclude count, etc.)
    // if (excludeCount) {
    //     message = <FormattedMessage 
    //         id="datasetValidWithExclude"
    //         values={{
    //             excludedColumns:  excludeCount > 1 ?
    //                 <FormattedMessage 
    //                     id="columnsExcluded" 
    //                     values={{count: excludeCount}}
    //                 /> :
    //                 <FormattedMessage 
    //                     id="columnExcluded" />
    //     }}
    //     />;
    // }

    const iconSx = { height: 16, width:16 };
    let icon = <CheckRoundedIcon sx={{...iconSx, color: "#00FF0E"}} />
    if (!fileModel.isUsable()) {
        message = <FormattedMessage id="datasetInvalid"/>
        color = theme.palette.error.main;        
        icon = <ErrorOutlineRoundedIcon sx={{...iconSx, color: color}} />
    } 
    if (fileModel.hasIssues()) {
        message = <FormattedMessage id="datasetIssues"/>
        color = theme.palette.warning.dark;
        icon = <WarningRoundedIcon sx={{...iconSx, color: color}} />
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'top', p: 0, m: 0 }}>
            <Box sx={{ marginRight: '4px' }}>                            
                <Typography sx={{color: color}}>
                    {message}
                </Typography>
            </Box>
            <Box>
                {icon}
            </Box>
        </Box >
    );
}


