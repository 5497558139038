// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';
import CodeIcon from '@mui/icons-material/Code';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const developer  = {
    id: 'developer',
    title: "Developer",
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'API',
            title: "Machina API",
            type: 'item',
            url: '/api-docs/',
            external: true,
            icon: CodeIcon,
            breadcrumbs: false
        },
    ]
};

export default developer;