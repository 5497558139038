import BaseModel from './BaseModel';
import DATASET_SERVICE from '_machina/service/DatasetService';

/**
 * Represents the current list of datasets
 */
class DatasetList extends BaseModel {
  /**
   * @constructor
   */
  constructor() {
    super();
    this._setResults = null;
  }
  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  setPageAndSort(page = null, pageSize = null, sortColumn = null, sortAscending = true) {
    this._page = page;
    this._pageSize = pageSize;
    this._sortColumn = sortColumn;
    this._sortAscending = sortAscending;
  }

  async deleteDatasets(datasetIds) {
    this.showStatus();
    let successCount = 0;
    try {
      for (let i = 0; i < datasetIds.length; i++) {
        const datasetId = datasetIds[i];
        await DATASET_SERVICE.delete(datasetId);
        successCount++;
      }
    } finally {
      if (successCount > 0) {
        await this.getDatasets();
      }
      this.hideStatus();
    }
  }  

  async getDatasets() {
    this.showStatus();
    try {
      const result = await DATASET_SERVICE.getList( this._page, this._pageSize, this._sortColumn, this._sortAscending );
      if (this._setResults) this._setResults(result);

    } finally {
      this.hideStatus();
    }
  }  

  _reset() {
    // this._previousScreen = null;
  }  
}

// Singleton
const DATASET_LIST = new DatasetList();

export default DATASET_LIST;
