import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

import STRING_UTIL from '_machina/util/StringUtil';
import MainCard from 'ui-component/cards/MainCard';

const DistributionChart = ({ selectedColumn, hideTitle, footer, onCategoryClick, overridesModel }) => {
    const [options, setOptions] = useState(null);

    const theme = useTheme();
    const intl = useIntl();

    React.useEffect(() => {
        if (!selectedColumn) return;

        const { primary } = theme.palette.text;
        const darkLight = theme.palette.dark.light;
        const successDark = theme.palette.success.dark;

        const data = []
        const dataColors = []
        const categories = []
        const categoriesColors = []

        const dist = selectedColumn.getDistribution();
        for (let i = 0; i < dist.length; i++) {
            const d = dist[i]
            data.push(d[1])
            dataColors.push(primary);
            categories.push("" + overridesModel.getColumnValueAlias(selectedColumn.getName(), d[0]));
            categoriesColors.push(primary);
        }

        if (STRING_UTIL.isOnlyDates(categories)) {
            for (let cidx = 0; cidx < categories.length; cidx++) {
                categories[cidx] = STRING_UTIL.stripTime(categories[cidx]);
            }
        }

        setOptions({
            data: data,
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (e, x, z) => {
                        const index = z.dataPointIndex;
                        console.log(dist[index][0]);
                        const category = dist[index][0]; //categories[index];                        
                        if (onCategoryClick) onCategoryClick(category);
                    },
                    xAxisLabelClick: (e, x, y) => {
                        console.log(dist[y.labelIndex][0]);
                        const category = dist[y.labelIndex][0]; //categories[y.labelIndex];
                        if (onCategoryClick) onCategoryClick(category);
                    }
                },
                parentHeightOffset: 0,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: categories,
                labels: {
                    style: {
                        colors: categoriesColors
                    }
                },
                features: {
                    sort: false
                }
            },
            colors: [successDark],
            yaxis: {
                labels: {
                    style: {
                        colors: dataColors
                    }
                }
            },
            grid: {
                borderColor: darkLight + 20
            },
            tooltip: {
                theme: 'dark'
            }
        });
    }, [selectedColumn]);

    if (!options) return;

    return (
        <div>
            <MainCard
                headerSX={{ m: 1.5, p: 0 }}
                contentSX={{ m: 0, my: -3, p: 0, px: 2 }}
                sx={{
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 10 : theme.palette.grey[200],
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.grey[200],
                }}
                titleContent={hideTitle ? null : <Typography variant="h4"><FormattedMessage id="valueDistribution" /></Typography>}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ height: '205px' }}>
                            <ReactApexChart options={options}
                                series={[
                                    {
                                        name: intl.formatMessage({ id: "count" }),
                                        data: options?.data ? options.data : []
                                    }
                                ]} type="bar" height={205} />
                        </div>
                    </Grid>
                    {footer && (
                        <Grid item xs={12}>
                            {footer}
                        </Grid>
                    )}
                </Grid>
            </MainCard>
        </div>
    );
};

export default DistributionChart;
