import BaseService from './BaseService';
import CURRENT_USER from '_machina/react/model/CurrentUser';

class TextToImageService extends BaseService {
    async submit(submitJson) {
        const url = `/api/text-to-image/submit`;
        return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, submitJson)
    }

    async status(uuid) {
        const url = `/api/text-to-image/status`;
        return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, {
            uuid: uuid
        })
    }

    async retrieve(uuid) {
        const url = `/api/text-to-image/retrieve`;
        const res = await this._fetchPost(
            await CURRENT_USER.getAuthToken(), url, 
            { uuid: uuid },
            true, // stringify
            'application/json', // content type
            false, // complete url
            true // raw response
        )        
        const blob = await res.blob();
        return await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          }
        );
    }
}

// Singleton
const TEXT_TO_IMAGE_SERVICE = new TextToImageService();

export default TEXT_TO_IMAGE_SERVICE;
