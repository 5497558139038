import BaseModel from './BaseModel';
import CURRENT_USER from './CurrentUser';
import USER_SERVICE from '_machina/service/UserService';

/**
 * Represents the current list of users
 */
class UserList extends BaseModel {
  /**
   * @constructor
   */
  constructor() {
    super();
    this._setResults = null;
  }
  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
  }

  setPageAndSort(page = null, pageSize = null, sortColumn = null, sortAscending = true) {
    this._page = page;
    this._pageSize = pageSize;
    this._sortColumn = sortColumn;
    this._sortAscending = sortAscending;
  }

  async deleteUsers(userIds) {    
    this.showStatus();
    let successCount = 0;

    const currentUser = await CURRENT_USER.getCurrentUser();
    try {
      for (let i = 0; i < userIds.length; i++) {
        const userId = userIds[i];        
        if (!currentUser || currentUser.ID !== userId) {
          await USER_SERVICE.delete(userId);
          successCount++;
        }
      }
    } finally {
      if (successCount > 0) {
        await this.getUsers();
      }
      this.hideStatus();
    }
  }  

  async getUsers() {
    this.showStatus();
    try {
      const result = await USER_SERVICE.getList( this._page, this._pageSize, this._sortColumn, this._sortAscending );
      if (this._setResults) this._setResults(result);

    } finally {
      this.hideStatus();
    }
  }  

  _reset() {
    // this._previousScreen = null;
  }  
}

// Singleton
const USER_LIST = new UserList();

export default USER_LIST;
