import React from 'react';
import { useState } from 'react';

// material-ui
import { Avatar, Grid, ListItemText, ListItemIcon, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';

import { openTextToImageDialog } from './image/TextToImageDialog';
import { selectFile, toBase64 } from '_machina/react/Util';
import APP from '_machina/react/model/App';
import CommonButton from '_machina/react/components/common/fields/CommonButton';
import CommonMenu from '_machina/react/components/common/CommonMenu';

export default function CommonImageChooser({ avatarMode, currentImage, onSetImage, justifyContent, width, defaultImageUrl, getChooserState, setChooserState }) {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [img, setImg] = React.useState(null);

    const closeMenu = () => { setMenuAnchorEl(null); }

    const MAX_IMAGE_SIZE_MB = 1;

    justifyContent = justifyContent || 'left';

    const selectImage = async () => {
        const file = await selectFile('image/*', false);
        if (file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
            APP.showWarningMessage(
                <FormattedMessage id="warning.image.maxSize" values={{ maxSize: MAX_IMAGE_SIZE_MB }} />
            );
            return;
        }
        const base64 = await toBase64(file);
        onSetImage(base64);
    }

    const props = {}
    if (!avatarMode) props.variant = 'rounded';
    const content = !avatarMode ? "" : null;
    const widthOut = width ? width : avatarMode ? 160 : 220;
    const height = avatarMode ? widthOut : widthOut * .75;

    const sx = {}
    if (!avatarMode) sx.backgroundColor = 'transparent';

    React.useEffect(() => {
        const tempImage = new Image();
        tempImage.onload = (e) => {
            setImg(e.target.src)
        }
        tempImage.onerror = (e) => {
            setImg(defaultImageUrl);
        }
        tempImage.src = currentImage;
    }, [currentImage, defaultImageUrl, setImg])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: justifyContent, ...sx }}>
                <Avatar
                    {...props}
                    src={img || currentImage || defaultImageUrl}
                    sx={{ width: widthOut, height: height, cursor: 'pointer', }}
                    onClick={selectImage}>{content}</Avatar>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: justifyContent }}>
                <Grid container sx={{ justifyContent: justifyContent }}>
                    <Grid item sx={{ minWidth: widthOut, textAlign: 'center' }}>
                        <CommonButton onClick={(e) => {setMenuAnchorEl(e.currentTarget)}}>
                            <FormattedMessage id="selectImage" />
                        </CommonButton>
                    </Grid>
                </Grid>
            </Grid>
            {menuAnchorEl &&
                <CommonMenu
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    anchorEl={menuAnchorEl}
                    onClose={closeMenu}
                >
                    <MenuItem
                        onClick={() => {
                            selectImage();
                            closeMenu();
                        }}
                    >
                        <ListItemIcon>
                            <InsertDriveFileRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            {/* <FormattedMessage id="selectAll" /> */}
                            File...
                        </ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            openTextToImageDialog(
                                avatarMode,
                                getChooserState, 
                                setChooserState,
                                (img || currentImage || defaultImageUrl),
                                (imageUrl) => {
                                    onSetImage(imageUrl);
                                }
                            );
                            closeMenu();
                        }}
                    >
                        <ListItemIcon>
                            <ImageRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            {/* <FormattedMessage id="unselectAll" /> */}
                            Generate...
                        </ListItemText>
                    </MenuItem>
                </CommonMenu>
            }

        </Grid>
    );
}
