import BaseService from './BaseService';
import CURRENT_USER from '_machina/react/model/CurrentUser';

/**
 * Service used to access workflows
 */
class WorkflowService extends BaseService {
    async importDataset(importJson) {
        const url = `/api/workflow/import-dataset`;
        return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, importJson)
    }

    async train(trainJson) {
        const url = `/api/workflow/train`;
        return await this._fetchPost(await CURRENT_USER.getAuthToken(), url, trainJson)
    }
}

// Singleton
const WORKFLOW_SERVICE = new WorkflowService();

export default WORKFLOW_SERVICE;
