import CommonTable from "../table/CommonTable";

export default function TableSkeleton({ sx, tableName, cardMode, headCells, renderToolbarItems, renderRow, renderCard, rowCount, updateResultsCallback, ...other }) {

    const results = {}
    const rows = []
    for (let i = 0; i < rowCount; i++) {
        rows.push({ID: i})
    }
    results.Results = rows;
    results.TotalCount = rows.length;

    return (
        <CommonTable
            isSkeleton={true}
            tableName={tableName}
            cardView={cardMode}
            headCells={headCells}
            updateResultsCallback={updateResultsCallback}
            results={results}
            renderToolbarItems={renderToolbarItems}
            renderRow={renderRow}
            renderCard={renderCard}
        />
    )
}

