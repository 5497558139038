import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { DialogActions, Box, Stepper, Step, StepLabel, Divider, Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import CommonButton from '../fields/CommonButton';

const getTotalStepCount = (steps, state) => {
    let total = 0;
    for (let i = 0; i < steps.length; i++) {        
        const s = steps[i];
        if (s.isEnabled === undefined || s.isEnabled(state)) {
            total++;
        }
    }    
    return total;
}

export function CommonWizard({ 
    height,
    onCancel,
    onFinish,
    title, 
    open, 
    setOpen, 
    step, 
    setStep, 
    steps, 
    stepsInfo,
    stepPanels, 
    state, 
    setState  }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isFinish = getTotalStepCount(steps, state) === step + 1;

    // console.log(state)

    const doCancel = () => {
        if (!onCancel || onCancel()) {
            setOpen(false);
        }
    }

    const doFinish = () => {
        if (!onFinish || onFinish()) {
            setOpen(false);
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            scroll="paper"
            fullWidth={true}
            maxWidth={"md"}
            onClose={(event, reason) => { 
                if (reason && reason == "backdropClick") return;
                doCancel(); 
            }}
        >
            {open && (
                <>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent sx={{ height: height ? height : 400}}>
                        <Stepper activeStep={step} sx={{ pt: 3, pb: 4 }}>
                            {steps.map((s) => {
                                return (
                                    s.isEnabled === undefined || s.isEnabled(state) ?
                                    <Step key={s.key}>
                                        <StepLabel>{s.title}</StepLabel>
                                    </Step> : null
                                )
                            })}
                        </Stepper>

                        <Divider />
                        <Box sx={{marginTop: 2.5, marginBottom: 0, px: 2, py: 0}}>
                            {stepPanels}
                        </Box>
                        {/* <DialogContent sx={{ marginTop: 1, px: 2 }}>
                            {stepPanels}
                        </DialogContent> */}
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <CommonButton
                            sx={{ mr: 2 }}
                            onClick={() => { doCancel(); }}                        >
                            <FormattedMessage id="cancel"/>
                        </CommonButton>
                        <CommonButton
                            disabled={step === 0}
                            onClick={() => { 
                                if (step > 0) {
                                    const onPrev = steps[step].onPrev;
                                    if (onPrev) onPrev(state, setState, stepsInfo);
                                    setStep(step - 1)
                                }                                
                            }}>
                            <FormattedMessage id="back"/>
                        </CommonButton>
                        <CommonButton
                            variant="outlined"
                            onClick={async () => { 
                                const onNext = steps[step].onNext;
                                if (!onNext || await onNext(state, setState, stepsInfo)) {
                                    if (isFinish) {
                                        doFinish();
                                    } else {
                                        setStep(step + 1)}}
                                    }
                                }
                            >
                            {isFinish ? <FormattedMessage id="finish"/> : <FormattedMessage id="next"/>}
                        </CommonButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

export function CommonWizardStepPanel({value, steps, stepKey, children}) {
    return (
      <div hidden={steps[value].key !== stepKey}>
          {children}
      </div>
    );
  }
  
