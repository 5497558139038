import React from 'react';

import { FormattedMessage } from 'react-intl';
import CommonSliderField from '_machina/react/components/common/fields/CommonSliderField';

export default function IterationsSlider({ state, setState }) {
    return (
        <CommonSliderField
            sx={{ maxWidth: 400 }}
            label={<FormattedMessage id="iterations" />}
            min={1} step={1} max={10}
            value={state.iterations}
            onChange={(value) => {
                state.iterations = value;
                setState({ ...state })
            }}
        />
    );
}
