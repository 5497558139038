import * as React from 'react';

import TableRowsIcon from '@mui/icons-material/TableRows';
import GridViewIcon from '@mui/icons-material/GridView';
import SearchIcon from '@mui/icons-material/Search';

import {
    Grid,
    Typography,
    InputAdornment,
    TextField,
    Tooltip,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';

import { alpha, useTheme } from '@mui/material/styles';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import CommonButton from './fields/CommonButton';

function CardModeToggle({ cardMode, setCardMode }) {
    const theme = useTheme();
    const size = '24px';
    const toggleSx = { height: size, width: size, padding: 2, borderColor: alpha(theme.palette.divider, .4) };

     return (
        <Grid container justifyContent="center">
            <ToggleButtonGroup value={cardMode} exclusive 
                onChange={(e, v) => { 
                    setCardMode(v !== null ? v : cardMode);
                }} 
                color="primary">
                <ToggleButton sx={toggleSx} value={true}>
                    <Tooltip title={"Grid View"}>
                        <GridViewIcon />
                    </Tooltip>
                </ToggleButton>
                <ToggleButton sx={toggleSx} value={false}>
                    <Tooltip title={"Table View"}>
                        <TableRowsIcon />
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    );
}

export default function CommonPageHeader({ title, search, searchPlaceholder, cardMode, setCardMode, children, createText, onCreate}) {
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            {createText && onCreate && (
                                <Grid item>
                                    <CommonButton onClick={() => onCreate()}>{createText}</CommonButton>
                                </Grid>
                            )}
                            {setCardMode && (
                                <Grid item alignItems="center">
                                    <CardModeToggle cardMode={cardMode} setCardMode={setCardMode} />
                                </Grid>
                            )}
                            {search && (
                                <Grid item>
                                    <TextField                                                                                                                                                         
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon fontSize="small" />
                                                </InputAdornment>
                                            )
                                        }}
                                        // onChange={handleSearch}
                                        placeholder={searchPlaceholder}
                                        value={""}
                                        size="small"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            }
        >
            {children}
        </MainCard>
    );
}