import React from 'react';

// material-ui
import { TextField } from '@mui/material';

export default function CommonTextField({ children, sx, ...other }) {
    return (
        <TextField
            size="small"                 
            variant="outlined"            
            fullWidth
            sx={{ maxWidth: 450, ...sx }}
            {...other}
        >
            {children}
        </TextField>
    );
}
