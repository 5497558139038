/**
 * Base model for various model objects
 */
export default class BaseModel {
  constructor() {
    this._statusCallback = null;
  }

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    this.setStatusCallback(statusCb);
  }

  /**
   * The callback used to receive status messages
   * @param {function} cb Callback
   */
  setStatusCallback(cb) {
    this._statusCallback = cb;
  }

  /**
   * Shows the status indicator (busy)
   * @param {string} message An optional message
   * @param {string} openDelay The amount of time to wait before showing status
   */
  showStatus(message = null, openDelay = 500) {
    if (this._statusCallback) {
      this._statusCallback.onShow(message, openDelay);
    }
  }

  /**
   * Hides the status indicator (busy)
   */
  hideStatus() {
    if (this._statusCallback) {
      this._statusCallback.onHide();
    }
  }
}

/**
 * Callback interface for showing/hiding status (busy)
 */
export class StatusCallback {
  /**
   * Shows the status (busy)
   * @param {string} message An optional message
   */
  onShow(message = null) {}
  /**
   * Hides the status (busy)
   */
  onHide() {}
}
