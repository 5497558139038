import React from 'react';

import { Typography, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import CommonImageChooser from '_machina/react/components/common/fields/CommonImageChooser';
import CommonTextField from '_machina/react/components/common/fields/CommonTextField';
import { CommonWizardStepPanel } from '_machina/react/components/common/wizard/CommonWizard';
import Transitions from 'ui-component/extended/Transitions';

export default function CommonObjectDetailsStepPanel({ header, step, steps, stepKey, object, setObject, defaultImageUrl, addValidatorCallback, validator, other, getChooserState, setChooserState }) {

    addValidatorCallback(() => {
        validator.checkMinLength(0, stepKey + ".name", object.Name);
    })

    return (
        <>
            {object &&
                <CommonWizardStepPanel value={step} steps={steps} stepKey={stepKey} >
                    {steps[step].key === stepKey && (
                        <Transitions type="fade" in={true}>
                            <Grid container spacing={3}>
                                {header && (
                                    <Grid item xs={12} md={9} sx={{ pb: 1 }}>
                                        <Typography>
                                            {header}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CommonTextField
                                                label={<FormattedMessage id="name" />}
                                                value={object.Name}
                                                onChange={(e) => {
                                                    object.Name = e.target.value;
                                                    setObject({ ...object })
                                                }}
                                                required
                                                helperText={
                                                    !validator.isValid(0, (stepKey + ".name"))
                                                        ? <FormattedMessage id="validation.name" /> : null
                                                }
                                                error={!validator.isValid(0, stepKey + ".name")}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CommonTextField
                                                multiline
                                                rows={5}
                                                label={<FormattedMessage id="description" />}
                                                value={object.Description}
                                                onChange={(e) => {
                                                    object.Description = e.target.value;
                                                    setObject({ ...object })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CommonImageChooser
                                                width={240}
                                                justifyContent="center"
                                                currentImage={object.ImageURL || defaultImageUrl}
                                                onSetImage={(image) => {
                                                    object.ImageURL = image;
                                                    setObject({ ...object })
                                                }}
                                                getChooserState={getChooserState}
                                                setChooserState={setChooserState}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {other}
                            </Grid>
                        </Transitions>)}
                </CommonWizardStepPanel>
            }
        </>
    );
}
