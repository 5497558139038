import * as React from 'react';

import RectangularSkeleton from './RectangularSkeleton';
import { IconButton } from '@mui/material';

export default function TableIconButtonSkeleton({ hasSubTitle, children }) {
  
  return (
    <IconButton>
        <RectangularSkeleton sx={{ width: 20, height: 20 }} />
    </IconButton>
  );
}