import React from 'react';

// material-ui
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { CommonDialogTabPanel } from '_machina/react/components/common/dialog/CommonDialog';
import CommonTextField from '_machina/react/components/common/fields/CommonTextField';
import CommonSwitch from '../fields/CommonSwitch';

export default function CommonObjectDetailsTabPanel({ tab, value, object, setObject, addValidatorCallback, validator, ownableUser }) {

    const validatorCallbackName = "common-object-details-tab" + value;
    addValidatorCallback(validatorCallbackName, () => {
        validator.checkMinLength(value, validatorCallbackName + ".name", object.Name);
    })

    return (
        <CommonDialogTabPanel tab={tab} value={value}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CommonTextField
                        label={<FormattedMessage id="name" />}
                        value={object.Name}
                        onChange={(e) => {
                            object.Name = e.target.value;
                            setObject({ ...object })
                        }}
                        required
                        helperText={
                          !validator.isValid(value, (validatorCallbackName + ".name"))
                            ? <FormattedMessage id="validation.name" /> : null
                        }
                        error={!validator.isValid(value, validatorCallbackName + ".name")}    
                    />
                </Grid>
                <Grid item xs={12}>
                    <CommonTextField
                        multiline
                        rows={5}
                        label={<FormattedMessage id="description" />}
                        value={object.Description}
                        onChange={(e) => {
                            object.Description = e.target.value;
                            setObject({ ...object })
                        }}
                    />
                </Grid>
                {(ownableUser && object.UserID && object.UserID === ownableUser.ID) &&
                    <Grid item xs={12}>
                        <CommonSwitch
                            label={<FormattedMessage id="publiclyAccessible" />}
                            checked={object.Public}
                            onChange={
                            () => {
                                object.Public = !object.Public
                                setObject({ ...object })
                            }                            
                        }                    
                        />                        
                    </Grid>
                }   
            </Grid>
        </CommonDialogTabPanel>
    );
}
