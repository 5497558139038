import { IconButton } from "@mui/material";
import EditTwoTone from "@mui/icons-material/EditTwoTone";

import Transitions from "ui-component/extended/Transitions";

import VIEW_MODEL from "_machina/react/model/ViewModel";

export default function EditFeatureButton({isTarget, featureName}) {
  const isPredict = VIEW_MODEL.isPredictMode();

  return (
    ((isPredict && !isTarget) || (!isPredict && isTarget)) && (      
      <Transitions type={"fade"} in={true}>
        <IconButton
          color="text-primary"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            if (!isTarget) {
              VIEW_MODEL.openFeatureEditor(featureName);
            } else {
              VIEW_MODEL.openTargetEditor();
            }
          }}
        >
          <EditTwoTone sx={{ fontSize: '1.2rem', opacity: isTarget ? 1.0 : 0.7 }} />
        </IconButton>
      </Transitions>
    )
  );
}

