import * as React from 'react';

import { useTheme } from '@mui/material/styles';

import {
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';


import CommonTooltip from './CommonTooltip';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

export default function CommonCardView({ title, onClick, description, imageUrl, defaultImageUrl, handleMenuClick, upperRightComponent, ...other }) {
    const [img, setImg] = React.useState(null);
    const theme = useTheme();

    React.useEffect(() => {
        const tempImage = new Image();
        tempImage.onload = (e) => {
            setImg(e.target.src)
        }
        tempImage.onerror = (e) => {
            setImg(defaultImageUrl);
        }
        tempImage.src = imageUrl;
    }, [imageUrl, defaultImageUrl, setImg])

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
        maxWidth: 300,
        margin: 'auto'
    };

    return (
        <Card sx={cardStyle} {...other}>
            <div style={{ position: "relative" }}>
                <CardMedia sx={{ height: 150, cursor: onClick ? 'pointer' : undefined }}
                    onClick={onClick}
                    component="img"
                    image={
                        img ? img :
                            (imageUrl ? imageUrl : defaultImageUrl)} />

                {upperRightComponent &&
                    <div style={{ position: "absolute", color: "white", top: '6px', left: "100%", paddingRight: '6px', transform: "translateX(-100%)", }}>
                        {upperRightComponent}
                    </div>
                }
            </div>
            <CardContent sx={{ p: 0, m: 1.5, '&:last-child': { pb: 0 } }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ overflow: 'hidden' }} xs={10}>
                        <Typography onClick={onClick} sx={{ cursor: onClick ? "pointer" : "default", textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} variant="subtitle1">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: "right" }} >
                        <IconButton size="small" onClick={handleMenuClick}>
                            <MoreHorizOutlinedIcon
                                fontSize="small"
                                color="inherit"
                            />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sx={{ overflow: 'hidden' }} >
                        <CommonTooltip title={description}>
                            <Typography sx={{ cursor: "default", textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} variant="subtitle2">
                                {description}
                            </Typography>
                        </CommonTooltip>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
