// material-ui
import { Card, CardContent, Grid } from '@mui/material';
import RectangularSkeleton from '_machina/react/components/common/skeleton/RectangularSkeleton';


function Feature() {
    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2} justifyContent="space-between">
                        <Grid item xs={6}>
                            <RectangularSkeleton height={20} />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container alignItems="center" spacing={2} justifyContent="space-between">
                                <Grid item xs zeroMinWidth>
                                    <RectangularSkeleton height={20} />
                                </Grid>
                                <Grid item>
                                    <RectangularSkeleton height={16} width={16} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <RectangularSkeleton height={20} />
                </Grid>
            </Grid>
        </Grid>
    )
}

const FeaturesSkeleton = () => (
    <Card>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs zeroMinWidth>
                            <RectangularSkeleton height={20} />
                        </Grid>
                        <Grid item>
                            <RectangularSkeleton height={20} width={20} />
                        </Grid>
                    </Grid>
                </Grid>
                <Feature/>
                <Feature/>
                <Feature/>
                <Feature/>
                <Feature/>
                <Feature/>
            </Grid>
        </CardContent>
        <CardContent sx={{ p: 1.25, display: 'flex', pt: 0, justifyContent: 'center' }}>
            <RectangularSkeleton height={25} width={75} />
        </CardContent>
    </Card>
);

export default FeaturesSkeleton;
