import LockPersonIcon from '@mui/icons-material/LockPerson';

export default function PrivateObjectIcon({forCard}) {
    if (forCard) {
        return (        
            <LockPersonIcon sx={{
                fontSize: 32, 
                padding: '4px', 
                backgroundColor: 'black', 
                opacity: 0.57, 
                borderRadius: 1.4, 
                border: '1px solid #666'}}/>
        );
    } else {
        return (
            <LockPersonIcon sx={{
                fontSize: 16,
                opacity: .6, 
            }}/>
        );
    }
}

