import { Skeleton } from "@mui/material";

export default function RectangularSkeleton({sx, ...other}) {
    return (
        <Skeleton  
            sx={{...sx, borderRadius: 1}}
            variant="rectangular" 
            {...other}
        />
    )
}

