import React from 'react';

import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { getDefaultDatasetUrl, getDefaultModelUrl } from '_machina/assets/images';

import CommonObjectDetailsStepPanel from '_machina/react/components/common/wizard/CommonObjectDetailsStepPanel';
import IterationsSlider from "../common/IterationsSlider"
import DatasetOverridesModel from '../DatasetOverridesModel';

let datasetValidatorCallback = null;

export const createDatasetDetailsStep = (validator) => {
  return {
    key: "datasetDetails",
    title: <FormattedMessage id="datasetDetails" />,
    onNext: async (state, setState, forceUpdate) => {
      validator.reset();
      datasetValidatorCallback();
      if (validator.getMinInvalidTab() >= 0) {
        forceUpdate();
        return false;
      }

      if (state.trainModel) {
        state.model = {
          Name: state.dataset.Name,
          Description: state.dataset.Description,
          ImageURL: state.dataset.ImageURL
        }
      }

      // Make sure rows are loaded for preview
      await new DatasetOverridesModel(state).loadPreviewRows();

      return true;
    },
    isEnabled: (state) => { return true; }
  }
}

export function DatasetDetailsStep({ datasetDetailsStep, step, steps, state, setState, validator, setChooserState, getChooserState }) {
  return (
    <CommonObjectDetailsStepPanel
      header={<FormattedMessage id="wizard.dataset.details.header" />}
      step={step}
      steps={steps}
      stepKey={datasetDetailsStep.key}
      defaultImageUrl={state.trainModel ? getDefaultModelUrl() : getDefaultDatasetUrl()}
      object={state.dataset}
      setObject={(object) => {
        state.dataset = object;
        setState({ ...state })
      }}
      getChooserState={getChooserState}
      setChooserState={setChooserState}
      addValidatorCallback={(cb) => { datasetValidatorCallback = cb }}
      validator={validator}
      other={
        state.trainModel && (
          <Grid item xs={12}>
            <IterationsSlider state={state} setState={setState} />
          </Grid>
        )
      }
    />
  );
}
