import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import APP from './model/App';
// import CommonFade from '../misc/CommonFade';

/**
 * Busy screen
 * @param {object} props The component properties
 * @returns The busy screen content
 */
export default function BusyScreen(props) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(null);

  APP._busyScreenSetOpen = (open, delay = 500) => {
    if (timeoutId) {
      clearTimeout(timeoutId);        
    }
    if (open) {
      setTimeoutId(setTimeout(() => {        
        APP.onBusyScreenOpen();
        setShowBackdrop(true);
      }, delay));
    } else {
      setShowBackdrop(false);
    }
    setOpen(open);
  }
  APP._busyScreenSetMessage = setMessage;

  return (
    <Modal open={open} disableAutoFocus={true} hideBackdrop={true}>
      {!showBackdrop ?
        <div></div> :
        <Backdrop
          open={open}
          sx={{
            backgroundColor: 'rgba(0,0,0,0.7)',
          }}
        >
          <Grid
            sx={{ textAlign: 'center' }}
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
            {message && (
              <Grid sx={{ pt: 1 }} item>
                <Typography variant="body1">{message}</Typography>
              </Grid>
            )}
          </Grid>
        </Backdrop>}
    </Modal>
  );
}
