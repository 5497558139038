import { FC, useRef, useState } from 'react';
import { SankeyLabel, SankeyNode as ReavizSankeyNode, calculateDimensions, useChart } from 'reaviz';
import classNames from 'classnames';
import ellipsize from 'ellipsize';
import { SankeyMenu, SankeyMenuOption } from '../SankeyMenu/SankeyMenu.tsx';
import css from './SankeyNode.module.css';

const MIDDLE_WIDTH = 125;
const MIDDLE_WIDTH_MAX = 250;
const MIDDLE_PADDING = 20;
const LABEL_TRUNCATE_LENGTH = 16;
const CATEGORY_TRUNCATE_LENGTH = 22;

export interface SankeyNodeData {
  /**
   * Unique identifier for the node. Example: "sales"
   */
  id: string;

  /**
   * Label for the node. Example: "Sales"
   */
  label: string;

  /**
   * The count of data points that are represented by this node. Example: 50
   */
  value: number;

  /**
   * The category of the node. Example: "Department".
   */
  category: string;
}

export interface SankeyNodeProps {
  title: string;
  id: string;
  value: string;
  depth: number;
  metadata?: any;
  nodeCount: number;
  index: number;
  selection?: string;
  options?: SankeyMenuOption[];
  onMenuChange?: (option: SankeyMenuOption) => void;
}

export const SankeyNode: FC<SankeyNodeProps> = ({
  options,
  selection,
  nodeCount,
  index,
  metadata,
  onMenuChange,
  ...rest
}) => {
  const { value, depth } = rest;
  const ref = useRef<SVGTextElement | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const truncatedCategory = ellipsize(metadata?.category, CATEGORY_TRUNCATE_LENGTH);
  const { chartHeight } = useChart();

  if (depth === 1) {
    const { width } = calculateDimensions(metadata.label, 'Open Sans', 22);
    const size = Math.min(Math.max(width, MIDDLE_WIDTH) + MIDDLE_PADDING, MIDDLE_WIDTH_MAX);
    const centerY = chartHeight / 2;
    const centerX = size / 2;
    const hasOptions = options?.length > 0;
    const truncatedTitle = ellipsize(metadata.label, LABEL_TRUNCATE_LENGTH);

    return (
      <g
        style={{ transform: `translateX(calc(50% - ${centerX}px))` }}
      >
        <rect
          className={css.rect}
          width={size}
          height={chartHeight}
        />
        <text
          ref={ref}
          x={centerX}
          y={centerY - 50}
          fontSize={10}
          className={classNames(css.centerNodeCategory, {
            [css.clickable]: options?.length > 0
          })}
          dominantBaseline="middle"
          textAnchor="middle"
          onClick={() => {
            if (options?.length) {
              setMenuOpen(true);
            }
          }}
        >
          {hasOptions ? `${truncatedCategory} ▾` : truncatedCategory} 
        </text>
        <text
          x={centerX}
          y={centerY - 25}
          fontSize={20}
          dominantBaseline="middle"
          textAnchor="middle"
          className={css.centerNodeTitle}
        >
          {truncatedTitle} 
        </text>
        {/* <text
          x={centerX}
          y={centerY}
          fontSize={14}
          dominantBaseline="middle"
          textAnchor="middle"
          className={css.centerNodeValue}
        >
          {value.toLocaleString()}
        </text> */}
        <SankeyMenu
          open={menuOpen}
          reference={ref}
          selection={selection}
          options={options}
          onMenuClose={() => setMenuOpen(false)}
          onMenuChange={onMenuChange}
        />
      </g>
    );
  }

  const truncatedLabel = ellipsize(metadata?.label, LABEL_TRUNCATE_LENGTH);
  const showLabel = nodeCount > 10 ? index % 2 === 0 : true;

  return (
    <ReavizSankeyNode
      {...rest}
      opacity={(active, disabled) => active || !disabled ? 1 : disabled ? 0.2 : 0.9}
      tooltip={null}
      label={
        showLabel ? (
          <SankeyLabel
            fill="var(--label-color)"
            position="outside"
            opacity={(active, disabled) => active || !disabled ? 1 : disabled ? 0.2 : 0.9}
            className={css.label}
            format={({ x }) => (
              <>
                <tspan x={x} dy="-1em" fontSize={10}>{truncatedCategory}</tspan>
                <tspan x={x} dy="1.1em" fontSize={14} fontWeight={500}>{truncatedLabel}</tspan>
                {/* <tspan x={x} dy="1.2em">{metadata.value.toLocaleString()}</tspan> */}
              </>
            )}
          />
        ) : null
      }
    />
  );
};
